import {
    AdminCompanyList,   
} from '@/api/company'
import {getToken, removeToken, setToken,setSecurityToken} from '@/utils/auth'
import {resetRouter} from '@/router'
import {generateDepartmentList, generateSlideData} from "@/utils/data-generator";


// 用户记录
const state = {
    companyList: [],//角色列表
};

const mutations = {   
    SET_COMPANY_LIST: (state, list) => {
        console.log('companyList',list)
        state.companyList = list;
    },
   
}

const actions = {
    
    async AdminCompanyList({commit, state},{key}) {
        return AdminCompanyList({userId: getToken(),key}).then(response => {
            commit('SET_COMPANY_LIST', response.data);
        });
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
