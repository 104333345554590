import {getToken} from "@/utils/auth";
import {
    GetProjectQuestions, GetProjectList, GetModelList,GetQuestionTypeList,GetProvinceCityList,GetModelDetail
    , GetProjectQADetail, GetProjectQuotaList, DDList_Common, ProjectAnalisys,ProjectAnalisysV2,ProjectAnalisysV4,ProjectAnalisysV5, GetQuesAnswerCol, GetProjectRatio
    ,GetProjectAgentList
} from "@/api/survey";

const state = {
    projectQuestions:[],
    projectList: [],
    modelList: [],
    questionTypeList: [],
    provinceCityList: [],
    modelDetail: {},
    projectQADetail: {},
    projectQuotaList: [],
    cateList: [],
    cateList2024: [],
    projectAnalisys: [],
    ProjectAnalisysV2:[],
    ProjectAnalisysV4:[],
    ProjectAnalisysV5:[],
    questionList:[],
    answerList:[],
    colList:[],
    projectRatioList: [],
    projectAgentList:[],
};

const mutations = {
    GetProjectQuestions:(state, list) => {
        state.projectQuestions = list;
    },
    GetProjectList:(state, list) => {
        // console.log('list',list)
        list.forEach(ele => {
            if (ele.quotas){       
                var obj = []        
                var qts = ele.quotas.split('；;')
                qts.forEach(ele2 => {
                    var qt = ele2.split('^-')
                    var percent = (Math.round(qt[2]/qt[1] * 10000) /100.00)   
                    if (qt[1] == '0') {
                        percent = '-'
                    }            
                    obj.push({title: qt[0]+'('+qt[2]+'/'+qt[1]+')', num: qt[1], finishedNum: qt[2], percent })
                    
                });
                ele.qtList = obj
            } else {
                ele.qtList = []
            }
        });
        // console.log('list',list)
        state.projectList = list;
    },
    GetModelList:(state, list) => {
        state.modelList = list;
    },
    GetQuestionTypeList:(state, list) => {
        state.questionTypeList = list;
    },
    GetProvinceCityList:(state, list) => {
        state.provinceCityList = list;
    },
    GetModelDetail:(state, list) => {
        // console.log('list',list.data.model)
        state.modelDetail = list.data.model;
    },
    GetProjectQADetail:(state, list) => {
        // console.log('list',list.data.model)
        state.projectQADetail = list.data.model;
    },
    GetProjectQuotaList:(state, list) => {
        state.projectQuotaList = list;
    },
    GetCateList:(state, list) => {
        state.cateList = list;
    },
    GetCateList2024:(state, list) => {
        state.cateList2024 = list;
    },
    ProjectAnalisys:(state, list) => {
        state.projectAnalisys = list;
    },
    ProjectAnalisysV2:(state, list) => {
        state.ProjectAnalisysV2 = list;
    },
    ProjectAnalisysV4:(state, list) => {
        state.ProjectAnalisysV4 = list;
    },
    ProjectAnalisysV5:(state, list) => {
        state.ProjectAnalisysV5 = list;
    },
    GetQuestionList:(state, list) => {
        state.questionList = list;
    },
    GetAnswerList:(state, list) => {
        state.answerList = list;
    },
    GetColList:(state, list) => {
        state.colList = list;
    },
    GetProjectRatio:(state, list) => {
        state.projectRatioList = list;
    },
    GetProjectAgentList:(state, list) => {
        state.projectAgentList = list;
    },
}

let obj = {};

const actions = {
    async GetProjectQuestions({commit}, {userId,projectId,agent} = {}) {
        const {data} = await GetProjectQuestions({
            userId: userId,projectId,agent
        });
        commit('GetProjectQuestions', data);
    },
    async GetProjectList({commit}, {userId,sDate,eDate,key} = {}) {
        const {data} = await GetProjectList({
            userId,sDate,eDate,key
        });
        commit('GetProjectList', data);
    },
    async GetModelList({commit}, {userId,sDate,eDate,key} = {}) {
        const {data} = await GetModelList({
            userId,sDate,eDate,key
        });
        commit('GetModelList', data);
    },
    async GetQuestionTypeList({commit}, {userId} = {}) {
        const {data} = await GetQuestionTypeList({
            userId
        });
        commit('GetQuestionTypeList', data);
    },
    async GetProvinceCityList({commit}, {userId, typeId} = {}) {
        const {data} = await GetProvinceCityList({
            userId, typeId
        });
        commit('GetProvinceCityList', data);
    },
    async GetModelDetail({commit}, {userId,modelId} = {}) {
        const {data} = await GetModelDetail({
            userId: userId,modelId
        });
        commit('GetModelDetail', data);
    },
    async GetProjectQADetail({commit}, {userId,projectId} = {}) {
        const {data} = await GetProjectQADetail({
            userId: userId,projectId
        });
        commit('GetProjectQADetail', data);
    },
    async GetProjectQuotaList({commit}, {userId,projectId} = {}) {
        const {data} = await GetProjectQuotaList({
            userId: userId,projectId
        });
        commit('GetProjectQuotaList', data);
    },
    async GetCateList({commit}, {userId, typeId} = {}) {
        const {data} = await DDList_Common({
            userId, typeId
        });
        commit('GetCateList', data);
    },
    async GetCateList2024({commit}, {userId, typeId} = {}) {
        const {data} = await DDList_Common({
            userId, typeId
        });
        commit('GetCateList2024', data);
    },
    async ProjectAnalisys({commit}, {userId,projectId,includeTest} = {}) {
        const {data} = await ProjectAnalisys({
            userId: userId,projectId,includeTest
        });
        commit('ProjectAnalisys', data);
    },
    async ProjectAnalisysV2({commit}, {userId,projectId,includeTest} = {}) {
        const {data} = await ProjectAnalisysV2({
            userId: userId,projectId,includeTest
        });
        commit('ProjectAnalisysV2', data);
    },
    async ProjectAnalisysV4({commit}, {userId,projectId,includeTest} = {}) {
        const {data} = await ProjectAnalisysV4({
            userId: userId,projectId,includeTest
        });
        commit('ProjectAnalisysV4', data);
    },
    async ProjectAnalisysV5({commit}, {userId,projectId,includeTest} = {}) {
        const {data} = await ProjectAnalisysV5({
            userId: userId,projectId,includeTest
        });
        commit('ProjectAnalisysV5', data);
    },
    async GetQuestionList({commit}, {userId,projectId} = {}) {
        const {data} = await GetQuesAnswerCol({
            userId,parentId: projectId,checkType: 1
        });
        commit('GetQuestionList', data);
    },
    async GetAnswerList({commit}, {userId,questionId} = {}) {
        const {data} = await GetQuesAnswerCol({
            userId,parentId: questionId,checkType: 2
        });
        commit('GetAnswerList', data);
    },
    async GetColList({commit}, {userId,questionId} = {}) {
        const {data} = await GetQuesAnswerCol({
            userId,parentId: questionId,checkType: 3
        });
        commit('GetColList', data);
    },
    async GetProjectRatio({commit}, {userId,projectId} = {}) {
        const {data} = await GetProjectRatio({
            userId, projectId
        });
        commit('GetProjectRatio', data);
    },
    async GetProjectAgentList({commit}, {userId,projectId} = {}) {
        const {data} = await GetProjectAgentList({
            userId, projectId
        });
        commit('GetProjectAgentList', data);
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
