import request from "@/utils/request";
import {getUserMenu} from "@/api/user";

export function userRoleList({userId, key = ''}) {
    return request({
        url: `/appi/Users/UserRoleList?uId=${userId}&key=${key}`,
    })
}


export function getCompanyRoleList({userId}) {
    return request({
        url: `/appi/Users/GetCompanyRoleList?uId=${userId}`,
    })
}


export function userRoleDelete({roleId, userId}) {
    return request({
        url: `/appi/Users/UserRoleDelete?roleId=${roleId}&uId=${userId}`,
    })
}

export function userRoleDetail({roleId}) {
    return request({
        url: `/appi/Users/UserRoleDetail?roleId=${roleId}`,
    })
        .then(res => {
            // console.log('cao',res.data)
            const {data: {rd: data}} = res.data;
            Reflect.deleteProperty(data, 'rightArray');
            return Promise.resolve({data});
        })
}

export function UserRoleDetail_AllModule({roleId}) {
    return request({url: `/appi/Users/UserRoleDetail_AllModule?roleId=${roleId}`}).then(res => {
        const {data: {rd: data}} = res.data;
        return Promise.resolve({data:data.rightArray});
    });
}

export function userRoleModify({roleId, roleName, companyId, remark, userId, rightArray}) {
    // console.log('aa',rightStr);
    return request({
        url: `/appi/Users/UserRoleModify`,
        data: {
            roleId, roleName, companyId, remark, uId:userId, rightArray
        }
    })
}






export const PermissionOptions = ['添加', '删除', '修改', '系数设置', '导出','上下线'];



export function getRoleModuleList({userId}) {
    return getUserMenu({userId});
}

