import request, {baseUrl} from "@/utils/request";

// export function uploadFile({formData}) {
//     return request({url: `/appi/FIle/UploadFile`, data: formData}).then(res => {
//         const {filename, filesize} = res.data[0];
//         res.data = {name: filename, fileSize: filesize, url: getFileUrl({name: filename})};
//         return Promise.resolve(res);
//     });
// }
export function uploadFile({formData},subDir) {
    return request({url: `/appi/FIle/UploadFile?subDir=${subDir}`, data: formData}).then(res => {
        const {filename, filesize} = res.data[0];
        res.data = {name: filename, fileSize: filesize, url: getFileUrl({name: filename})};
        return Promise.resolve(res);
    });
}

//
// export function downloadFile({fileName}) {
//     return request({url: `/api/File/DownloadFile?fileName=${fileName}`, method: 'get'});
// }

export function getFileUrl({name,subDir=''}) {
    if (subDir=='')
        return `${baseUrl}/appi/File/DownloadFile?fileName=${name}`;
    else    
    return `${baseUrl}/appi/File/DownloadFile?fileName=${name}&subDir=${subDir}`;
}

export function downloadFileByResponseData(responseData, fileName = '') {
    let url = window.URL.createObjectURL(new Blob([responseData]));
    let link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


export function dealDownloadFileResponse({res, fileName}) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader(), data = res.data.data;
        reader.onload = e => {
            if (e.target.result.indexOf('Result') != -1 && JSON.parse(e.target.result).Result == false) {
                // 进行错误处理
                reject();
            } else {
                if (!fileName) {
                    let contentDisposition = res.data.headers['content-disposition'];
                    if (contentDisposition) {
                        fileName = window.decodeURI(res.data.headers['content-disposition'].split('=')[1], "UTF-8");
                    }
                }
                downloadFileByResponseData(data, fileName)
            }
        };
        reader.readAsText(data);
        resolve();
    })

}
