import request from '@/utils/request'
import user from "@/store/modules/user";
// import {dealDownloadFileResponse} from "@/api/file";
import { encrypt } from '@/utils/encrypt'

export function login(data) {
    
    return request({
        url: `/appi/UserCheck/UserCheck?uName=${data.uName}&uPswd=${data.uPswd}&ip=${data.ip}&cityName=${data.cityName}`,
        method: 'post',
    })
}

export function logout() {
    return request({
        url: '/vue-element-admin/user/logout',
        method: 'post'
    })
}

export function resetPassword({uName, newPswd}) {
    return request({
        url: `/appi/Users/UserModiPswd?uName=${uName}&newPswd=${newPswd}`,
        method: 'post',
    })
}

export function getUserMenu({userId}) {
    return request({
        url: `/appi/UserCheck/UserMenuGet?uId=${userId}`
    })
}

export function getUserCompany({userId}) {
    return request({
        url: `/appi/Users/UserCompanyGet?uId=${userId}`
    })
}

export function getUserList({userId, key = ''}) {
    return request({
        url: `/appi/Users/UserList?uId=${userId}&key=${key}`
    })
    // return request({url: `/appi/Users/UserList?uId=${userId}&key=${key}`}).then(res=>{
    //     res.data = res.data.map(item=>{
    //         const {typeId, typeName} = item;
    //         return {cateName:typeName, cateId: typeId}
    //     })
    //     return Promise.resolve(res);
    // })
}

export function getUserDepartmentList({userId, companyId = '', key = ''}) {
    return request({url: `/appi/Users/UserDepartmentList?uId=${userId}&companyId=${companyId}&key=${key}`});
}

export function setUserDepartmentModify({userId, departmentId , parentId,departmentName}) {
    return request({url: `/appi/Users/UserDepartmentModify`,data:{userId,departmentId,parentId, departmentName}});
}

export function deleteUserDepartmentListModify({userId, departmentId}) {
    return request({url: `/appi/Users/UserDepartmentDelete?deptId=${departmentId}&uId=${userId}`});
}

export function getUserRoleList({userId, key = ''}) {
    // console.log('aa',userId);
    return request({url: `/appi/Users/UserRoleList?uId=${userId}&key=${key}`})
}

// let obj = {
//     "uId": 8, "userName": "wfdd", "name": "wfdd", "telNo": "13655566",
//     "mail": "aa@bb.cc", "departmentId": 2, "roleId": 1, "sDate":, "eDate":, "isAdmin": 1,
//     "optUserId": 2
// };

export function setUserModify(data) {
    return request({
        url: `/appi/Users/UserModify`,
        data
    });
}

export function deleteUser({userId, optUserId}) {
    return request({
        url: `/appi/Users/UserDelete?uId=${userId}&optUserId=${optUserId}`,
    });
}

export function getUserDepartmentTargetList({departmentId, key = ''}) {
    return request({
        url: `/appi/Users/UserDepartmentTargetList?departmentId=${departmentId}&key=${key}`,
    }).then(response => {
        return {
            data: response.data.map(item => {
                const obj = {...item, targetId: item.targetid};
                Reflect.deleteProperty(item, 'targetid');
                return obj;
            })
        }
    })
}

export function addUserDepartmentTargetModify({targetId, departmentId, year, target, userId}) {
    return request({
        url: `/appi/Users/UserDepartmentTargetModify`,
        data: {targetId, departmentId, year, target, userId}
    })
}


export function deleteUserDepartmentTarget({targetId, userId}) {
    return request({
        url: `/appi/Users/UserDepartmentTargetDelete?targetId=${targetId}&uId=${userId}`,
    })
}

export function AdminCompanyModify(data) {
    return request({
        url: `/appi/Users/AdminCompanyModify`,
        data
    });
}

export function AdminCompanyDelete({userId, companyId}) {
    return request({
        url: `/appi/Users/AdminCompanyDelete?uId=${userId}&companyId=${companyId}`,
    });
}

export function resetPasswordByOldPassword({userId,oldPswd, newPswd}) {
    return request({
        url: `/appi/Users/UserCheckAndModiPswd?uID=${userId}&oldPswd=${oldPswd}&newPswd=${newPswd}`,
        method: 'post',
    })
}


/**
 * 导出用户
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function UserExport({userId, sDate,eDate}) {
    return request({
        url: `/appi/users/UserExport?uId=${userId}&sDate=${sDate}&eDate=${eDate}`,
        method: 'get',
        responseType: 'blob'
    }).then(res => {
        console.log(res);
        return dealDownloadFileResponse({res});
    })
}

export function UserRoleModuleDetail(params) {
    return request({
        url: `/appi/users/UserRoleModuleDetail?userId=${encrypt(params.userId)}&moduleId=${params.moduleId}`
    })
}