<template>
    <div class="sidebar">
        <!-- :default-active="onRoutes" -->
        <el-menu
            class="sidebar-el-menu"
            default-active="2"
            :default-openeds="openeds"
            :collapse="collapse"
            background-color="rgb(63 117 234)"
            text-color="#ffffff"
            active-text-color="#f64844"
            unique-opened
            router
        >
            <template v-for="item in slideData">
                <template v-if="item.children">
                    <el-submenu :index="item.moduleId" :key="item.moduleId">
                        <template #title>
                            <i style="color:white;" :class="item.style"></i>
                            <span>{{ item.moduleName }}</span>
                        </template>
                        <template v-for="subItem in item.children">
                            <el-submenu
                                v-if="subItem.children"
                                :index="subItem.url"
                                :key="subItem.moduleId"
                            >
                                <template #title>{{ subItem.moduleName }}</template>
                                <el-menu-item
                                    v-for="(threeItem, i) in subItem.children"
                                    :key="i"
                                    :index="threeItem.url"
                                >{{ threeItem.moduleName }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                v-else
                                :index="subItem.url"
                                :key="subItem.moduleId"
                            >{{ subItem.moduleName }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.url" :key="item.moduleId">
                        <i style="color:white;" :class="item.style"></i>
                        <template #title>{{ item.moduleName }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu> 
        
    </div>
</template>

<script>
// import bus from "../common/bus";
import { mapGetters } from "vuex";
import { toRaw } from '@vue/reactivity'

export default {
    computed: {
            ...mapGetters(['sidebar','sidebarDefaultIndex'])
        },

    data() {
        return {
            slideData: [],
            openeds: ['2'],
        };
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace("/", "");
        },
        collapse(){
            // console.log('this.$store.state.collapse',this.$store.state.collapse)
            return this.$store.state.collapse
        }
    },
    async mounted() {
        // // await this.$store.commit("setAnalysis_Month", {userId: 1, sDate:'2021-01-01', eDate:'2021-12-31'});
        // await this.$store.dispatch('screen/setAnalysis_Month', {userId: 1, sDate:'2021-01-01', eDate:'2021-12-31'})
        // // this.obj = toRaw(this.$store.state.Analysis_Month);
        await this.$store.dispatch('user/getUserMenu');
        this.$store.commit("hadndleCollapse", false);
        this.slideData = toRaw(this.$store.getters.sidebar);
        // console.log('store11',this.sidebar,this.$store.getters.sidebar,this.slideData)
    },
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 200px;
}
.sidebar > ul {
    height: 100%;
}
</style>
