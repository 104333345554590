// import { Message } from "element-ui";

// 用户记录
const state = {
    // cartList:[]
    cartList:localStorage.getItem('cartList')?JSON.parse(localStorage.getItem('cartList')):[]
};

const mutations = {
    //添加购物车
    addCart(state, selItem){
        const isAdded = state.cartList.find(item => item.groupId === selItem.groupId && item.item.sDate === selItem.sDate && item.item.eDate === selItem.eDate);
        //如果不存在设置购物车为1，存在count++
        // if(isAdded){
        //     this.$message({message: '已加购改时段的产品包，请勿重复加购',type: 'error'});
        //     // isAdded.count++;
        //     // console.log('aa',isAdded,state.cartList)
        // }else{
            state.cartList.push({
                groupId: selItem.groupId,
                checked:true,
                item:selItem,
                count: 1
            })
            // console.log('bb',isAdded,state.cartList)
        // }
        localStorage.setItem('cartList', JSON.stringify(state.cartList)); 
    },
    
    //删除购物车商品
    deleteCart(state, selItem){
        const index = state.cartList.findIndex(item => item.groupId === selItem.groupId && item.item.sDate === selItem.item.sDate && item.item.eDate === selItem.item.eDate);
        // console.log('index',state.cartList,selItem)
        if(index>-1){
            state.cartList.splice(index, 1);
            localStorage.setItem('cartList', JSON.stringify(state.cartList)); 
        }
    },
    //清空购物车
    emptyCart(state){
        state.cartList = [];
        localStorage.setItem('cartList', JSON.stringify(state.cartList)); 
    },

    //反选购物车所有内容
    setCartAllSelectStatus(state){
        var list = JSON.parse(localStorage.getItem('cartList'));
        list.forEach(ele => {
            ele.checked=!ele.checked;
        });
        // state.cartList =[];
        state.cartList = list;
        console.log('aa',list)
        localStorage.setItem('cartList', JSON.stringify(list)); 
    },

    //反选购物车指定内容
    setCartOneSelectStatus(state, selItem){
        // const index = state.cartList.findIndex(item => item.groupId === selItem.groupId && item.item.sDate === selItem.item.sDate && item.item.eDate === selItem.item.eDate);
        // if(index>-1){
        //     console.log('bbb1',state.cartList[index].checked)
        //     state.cartList[index].checked = !state.cartList[index].checked;
        //     console.log('bbb2',state.cartList[index].checked)
        //     localStorage.setItem('cartList', JSON.stringify(state.cartList)); 
        //     // state.cartList.splice(index, 1);
        //     // localStorage.setItem('cartList', JSON.stringify(state.cartList)); 
        // }
        var list = JSON.parse(localStorage.getItem('cartList'));
        // console.log('aaa1',JSON.parse(localStorage.getItem('cartList'))[0].checked)
        // console.log('bbb1',state.cartList[0].checked)
        const product = list.find(item => item.groupId === selItem.groupId && item.item.sDate === selItem.item.sDate && item.item.eDate === selItem.item.eDate);
        // console.log('bbb1',product.checked)
        product.checked = !product.checked;
        // console.log('bbb2',product.checked)
        // console.log('bbb2',state.cartList[0].checked)
        state.cartList = list;
        localStorage.setItem('cartList', JSON.stringify(list)); 
        // console.log('aaa2',JSON.parse(localStorage.getItem('cartList'))[0].checked)
    },

    // //修改购物车商品数量
    // editCartCount(state, payload){
    //     const product = state.cartList.find(item => item.groupId === payload.groupId);
    //     product.count += payload.count;
    // },

}

const actions = {
    async addCart({commit}, {selItem} = {}) {
        // selItem.checked=true;
        commit('addCart', selItem);
    },

    // async setCartAllSelectStatus({commit}, {}) {
    //     commit('setCartAllSelectStatus', selItem);
    // },
    
    // async setCartOneSelectStatus({commit}, {selItem}) {
    //     console.log('zheli')
    //     commit('setCartOneSelectStatus', selItem);
    // },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
