import {getToken} from "@/utils/auth";
// 
// import {formatSourceDataToSever} from "@/views/Operate/Content/style-xml-manager";
import {
    DDList,DDSingleList,CalculateValue_Province,CalculateValue,CalculateValueNew,CalculateProjectValue,GetProjectList
    ,DelProject,GetStationList,GetStationDetail,GetIndexData,ExportStationList,CalculateMulti
    //,GetGroupandSimpleMedia
    // ,GetMediaDetail
    ,GetPlanPrice,CalculateMultiSmart,CalculateMultiSmart_WithoutBudget
    ,GetGroupList,GetProductList,GetStationPositionMediaList,GetProductMediaList,GetGroupDetail,GetMediaList
    ,GetProjectSmartList,GetAdVersionList,GetProjectMediaList
    ,GetProjectProductMediaList
    ,DDListLevel2
    ,GetTrainPriceList
    ,getMessageListByUser
    ,GetPlanPrice_ByCost
    ,GetPlanPrice_ByGroupIds
    ,GetCartData
    ,GetMediaTagList
    ,GetGroupList_ByTag
    ,BuildCartItem
    ,GetTagGroupList
    ,GetStationInfo
    ,BuildCartItem_Multi
    ,SmartSuggest
    ,SmartSuggestNew
    ,GetTeSeTaoCanList
    ,GetMgrStationList
} from "@/api/operate";
// import {formatSourceDataToSever} from "@/views/BCSProduct/style-xml-manager";

const state = {
    CalculateValueList:[],
    CalculateValueListNew:[],
    CalculateValue_ProvinceList:[],
    CalculateProjectValueList:[],
    positionList:[],
    packageList:[],
    ageList:[],
    genderList:[],
    targetList:[],
    outComeList:[],
    mediaTypeList:[],
    stationList:[],
    dxTypeList:[],
    categoryList:[],
    productList:[],
    projectList:[],
    projectMediaList:[],
    projectMediaList_NotDetail:[],
    GetProjectProductMediaList:[],
    // stationList:[],
    smartCondition:{
        mtArray:[],
        dateArray:[],
        areaArray:[],
        rate:'',
        ddqr:'',
        jcqrc:'',
        pjblpc:'',
        zhf:'',
        cpm:'',
        xb:[],
        nl:[],
        xl:[],
        sr:[],
    },
    stationDetail:[],
    indexData:[],
    CalculateMulti:{},

    //GetGroupandSimpleMedia:[],
    // GetMediaDetail:[],
    GetPlanPrice:[],
    GetPlanPrice_ByCost:[],
    GetPlanPrice_ByGroupIds:[],
    CalculateMultiSmart:{},
    CalculateMultiSmart_WithoutBudget:{},
    GetGroupList:[],
    GetPackageMediaList:[],
    GetProductList:[],
    GetValidProductList:[],
    GetCartData:[],
    GetMediaTagList:[],
    GetTagGroupList:[],
    GetGroupList_ByTag:[],
    GetValidStandardProductList:[],
    GetStationPositionMediaList:[],
    GetProductMediaList:[],
    GetGroupDetail:{},
    Media_DxList:[],
    Media_LedList:[],
    Media_DigitalList:[],
    Media_TrainList:[],
    // Media_AllList:[],

    GetProjectSmartList:[],
    GetAdVersionList:[],
    

    InnerTrainPriceList:[],
    TvTrainPriceList:[],

    unreadMessageList: [],
    readedMessageList:[],

    // Analisys_Alert:[],
    BuildCartItem:{},
    GetStationInfo:{},
    BuildCartItem_Multi:[],
    AreaList:[],
    ReportTypeList:[],
    MediaOwnerList:[],
    ProvinceList:[],
    ProvinceCityStationList:[],
    CityLevelList:[],

    intelligenceAreaList:[],
    SmartSuggest:[],

    goldenList:[],
    GetTeSeTaoCanList:[],

    GetMgrStationList:[],
    AreaProvinceCityList:[],
    stationGradeList:[],
};

const mutations = {
    CalculateValueList:(state, list) => {
        state.CalculateValueList = list;
    },
    CalculateValueListNew:(state, list) => {
        state.CalculateValueListNew = list;
    },
    CalculateValue_ProvinceList:(state, list) => {
        state.CalculateValue_ProvinceList = list;
    },
    CalculateProjectValueList:(state, list) => {
        state.CalculateProjectValueList = list;
    },


    // GetGroupandSimpleMedia:(state, list) => {
    //     state.GetGroupandSimpleMedia = list;
    // },
    // GetMediaDetail:(state, list) => {
    //     state.GetMediaDetail = list;
    // },
    GetPlanPrice:(state, list) => {
        state.GetPlanPrice = list;
    },
    GetPlanPrice_ByCost:(state, list) => {
        state.GetPlanPrice_ByCost = list;
    },
    GetPlanPrice_ByGroupIds:(state, list) => {
        state.GetPlanPrice_ByGroupIds = list;
    },
    GetGroupList:(state, list) => {
        state.GetGroupList = list;
    },
    GetPackageMediaList:(state, list) => {
    //     var obj=[];
    //     var path = getImagePath();
    //     list[1].forEach(element => {
    //         var imgArr=element.images.split(';');
    //         var imgObj=[];
    //         if (imgArr && imgArr.length>0){
    //             imgArr.forEach(ele2 => {
    //                 imgObj.push(path+ele2);
    //             });                
    //         }
    //         element.imgList=imgObj;
    //         obj.push(element);
    //     });
    //     state.GetPackageMediaList = obj;
        state.GetPackageMediaList = list;
    },
    GetProductList:(state, list) => {
        state.GetProductList = list;
    },
    GetValidProductList:(state, list) => {
        state.GetValidProductList = list;
    },
    GetCartData:(state, list) => {
        state.GetCartData = list;
    },
    GetMediaTagList:(state, list) => {
        state.GetMediaTagList = list;
    },
    GetTagGroupList:(state, list) => {
        state.GetTagGroupList = list;
    },
    GetGroupList_ByTag:(state, list) => {
        state.GetGroupList_ByTag = list;
    },
    GetValidStandardProductList:(state, list) => {
        state.GetValidStandardProductList = list;
    },
    GetStationPositionMediaList:(state, list) => {
        state.GetStationPositionMediaList = list;
    },
    GetProductMediaList:(state, list) => {
        state.GetProductMediaList = list;
    },
    GetGroupDetail:(state, list) => {
        state.GetGroupDetail = list;
    },
    Media_DxList:(state, list) => {
        state.Media_DxList = list;
    },

    Media_LedList:(state, list) => {
        state.Media_LedList = list;
    },
    Media_DigitalList:(state, list) => {
        state.Media_DigitalList = list;
    },
    Media_TrainList:(state, list) => {
        state.Media_TrainList = list;
    },

    // Media_AllList:(state, list) => {
    //     state.Media_AllList = list;
    // },

    GetProjectSmartList:(state, list) => {
        state.GetProjectSmartList = list;
    },

    GetAdVersionList:(state, list) => {
        state.GetAdVersionList = list;
    },
    





    projectList:(state, list) => {
        state.projectList = list;
    },
    projectMediaList:(state, list) => {
        state.projectMediaList = list;
    },
    projectMediaList_NotDetail:(state, list) => {
        state.projectMediaList_NotDetail = list;
    },
    GetProjectProductMediaList:(state, list) => {
        state.GetProjectProductMediaList = list;
    },

    mediaTypeList:(state, list) => {
        state.mediaTypeList = list;
    },
    stationList:(state, list) => {
        state.stationList = list;
    },
    positionList:(state, list) => {
        state.positionList = list;
    },
    packageList:(state, list) => {
        state.packageList = list;
    },
    ageList:(state, list) => {
        state.ageList = list;
    },
    genderList:(state, list) => {
        state.genderList = list;
    },
    targetList:(state, list) => {
        state.targetList = list;
    },
    outComeList:(state, list) => {
        state.outComeList = list;
    },
    areaStationList:(state, list) => {
        state.areaStationList = list;
    },
    juDuanSuoList:(state, list) => {
        state.juDuanSuoList = list;
    },
    lujuXianluList:(state, list) => {
        state.lujuXianluList = list;
    },
    lieCheInnerJiaGeLeiXingList:(state, list) => {
        state.lieCheInnerJiaGeLeiXingList = list;
    },
    lieCheTvJiaGeLeiXingList:(state, list) => {
        state.lieCheTvJiaGeLeiXingList = list;
    },

    goldenList:(state, list) => {
        state.goldenList = list;
    },
    categoryList:(state, list) => {
        state.categoryList = list;
    },
    dxTypeList:(state, list) => {
        state.dxTypeList = list;
    },
    productList:(state, list) => {
        state.productList = list;
    },

    setSmartCondition: (state, data) => {
        state.smartCondition = data;
    },

    // stationList:(state, list) => {
    //     state.stationList = list;
    // },

    stationDetail:(state, list) => {
        state.stationDetail = list;
    },
    indexData:(state, list) => {
        state.indexData = list;
    },
    CalculateMulti:(state, list) => {
        state.CalculateMulti = list;
    },

    CalculateMultiSmart:(state, list) => {
        state.CalculateMultiSmart = list;
    },
    CalculateMultiSmart_WithoutBudget:(state, list) => {
        state.CalculateMultiSmart_WithoutBudget = list;
    },

    InnerTrainPriceList:(state, list) => {
        state.InnerTrainPriceList = list;
    },
    TvTrainPriceList:(state, list) => {
        state.TvTrainPriceList = list;
    },

    SET_UNREADMESSAGE_LIST: (state, list) => {
        // console.log('list',list)
        state.unreadMessageList = list;
    },
    SET_READEDMESSAGE_LIST: (state, list) => {
        state.readedMessageList = list;
    },

    Analisys_Alert: (state, list) => {
        state.Analisys_Alert = list;
    },

    BuildCartItem: (state, list) => {
        state.BuildCartItem = list;
    },

    GetStationInfo: (state, list) => {
        state.GetStationInfo = list;
    },
    BuildCartItem_Multi: (state, list) => {
        state.BuildCartItem_Multi = list;
    },
    AreaList: (state, list) => {
        state.AreaList = list;
    },
    ReportTypeList: (state, list) => {
        state.ReportTypeList = list;
    },
    MediaOwnerList: (state, list) => {
        state.MediaOwnerList = list;
    },
    CityLevelList: (state, list) => {
        state.CityLevelList = list;
    },
    ProvinceList: (state, list) => {
        state.ProvinceList = list;
    },
    ProvinceCityStationList: (state, list) => {
        state.ProvinceCityStationList = list;
    },

    intelligenceAreaList: (state, list) => {
        state.intelligenceAreaList = list;
    },

    SmartSuggest: (state, list) => {
        state.SmartSuggest = list;
    },
    GetTeSeTaoCanList: (state, list) => {
        state.GetTeSeTaoCanList = list;
    },

    GetMgrStationList: (state, list) => {
        state.GetMgrStationList = list;
    },
    AreaProvinceCityList: (state, list) => {
        state.AreaProvinceCityList = list;
    },
    stationGradeList: (state, list) => {
        state.stationGradeList = list;
    },
}

let obj = {};

const actions = {

    async CalculateValueList({commit}, {userId,sDate,eDate,stationIds,typeIds,positionIds} = {}) {
        const {data} = await CalculateValue({
            userId: userId || getToken(),sDate,eDate,stationIds,typeIds,positionIds
        });
        commit('CalculateValueList', data);
    },
    async CalculateValueListNew({commit}, {userId,sDate,eDate,stationIds,typeIds,positionIds,budget} = {}) {
        const {data} = await CalculateValueNew({
            userId: userId || getToken(),sDate,eDate,stationIds,typeIds,positionIds,budget
        });
        commit('CalculateValueListNew', data);
    },
    async CalculateMulti({commit}, {brand,budget,condi} = {}) {
        const {data} = await CalculateMulti({
            userId: getToken(),brand,budget,condi
        });
        commit('CalculateMulti', data);
    },

    // async CalculateMultiSmart({commit}, {brand,cateId,condi} = {}) {
    //     const {data} = await CalculateMultiSmart({
    //         userId: getToken(),brand,budget,condi,projectName:'',cateId:'',discount:'',cpm:'',frequency:'',personCnt:'',reachRate:''
    //     });
    //     commit('CalculateMultiSmart', data);
    // },

    async CalculateMultiSmart({commit}, {ip='',cityName='',budget='',ledDiscount='',dxDiscount='',lcDiscount='',condi} = {}) {
        // console.log('budget',budget)
        const {data} = await CalculateMultiSmart({
            userId: getToken(),ip,cityName,budget,ledDiscount,dxDiscount,lcDiscount,condi
        });
        commit('CalculateMultiSmart', data);
    },
    async CalculateMultiSmart_WithoutBudget({commit}, {ip='',cityName='',ledDiscount='',dxDiscount='',lcDiscount='',condi} = {}) {
        // console.log('budget',budget)
        const {data} = await CalculateMultiSmart_WithoutBudget({
            userId: getToken(),ip,cityName,ledDiscount,dxDiscount,lcDiscount,condi
        });
        commit('CalculateMultiSmart_WithoutBudget', data);
    },

    // async GetGroupandSimpleMedia({commit}, {stationId} = {}) {
    //     const {data} = await GetGroupandSimpleMedia({
    //         userId: getToken(),stationId
    //     });
    //     commit('GetGroupandSimpleMedia', data);
    // },

    // async GetMediaDetail({commit}, {mediaId} = {}) {
    //     const {data} = await GetMediaDetail({
    //         userId: getToken(),mediaId
    //     });
    //     commit('GetMediaDetail', data);
    // },

    async GetPlanPrice({commit}, {sDate,eDate,length,times,ledDisCount,dxDisCount,groupId,haveSelectListIds} = {}) {
        const {data} = await GetPlanPrice({
            userId: getToken(),sDate,eDate,length,times,ledDisCount,dxDisCount,groupId,haveSelectListIds
        });
        commit('GetPlanPrice', data);
    },

    async GetPlanPrice_ByCost({commit}, {sDate,eDate,times,disCount,cost} = {}) {
        const {data} = await GetPlanPrice_ByCost({
            userId: getToken(),sDate,eDate,times,disCount,cost
        });
        commit('GetPlanPrice_ByCost', data);
    },
    async GetPlanPrice_ByGroupIds({commit}, {groupIds,sDate,eDate,times,length,zuShu,disCount} = {}) {
        const {data} = await GetPlanPrice_ByGroupIds({
            userId: getToken(),groupIds,sDate,eDate,times,length,zuShu,disCount
        });
        commit('GetPlanPrice_ByGroupIds', data);
    },

    async GetGroupList({commit}, {stationIds='',typeIds='',ptIds='',key='',belowGroupId='',exceptIds='',isAll='0'} = {}) {
        const {data} = await GetGroupList({
            userId: getToken(),stationIds,saleStatus:'',playStatus:'',typeIds,ptIds,key,belowGroupId,exceptIds,isAll
        });
        commit('GetGroupList', data);
    },

    async GetProductList({commit}, {stationIds='',saleStatus='',playStatus='',key='',isValid=0,packageIds='',productType=-1} = {}) {
        const {data} = await GetProductList({
            userId: getToken(),stationIds,saleStatus,playStatus,key,isValid,packageIds,productType
        });
        commit('GetProductList', data);
    },

    async GetValidProductList({commit}, {stationIds='',saleStatus='',playStatus='',key='',isValid=1,packageIds='',productType=1} = {}) {
        const {data} = await GetProductList({
            userId: getToken(),stationIds,saleStatus,playStatus,key,isValid,packageIds,productType
        });
        commit('GetValidProductList', data);
    },

    async GetCartData({commit}, {sDate,eDate,times,length,packageIds='',typeId=0,sids='',tids=''} = {}) {
        // console.log('do')
        const {data} = await GetCartData({
            userId: getToken(),sDate,eDate,times,length,packageIds,typeId,sids,tids
        });
        commit('GetCartData', data);
    },

    async GetMediaTagList({commit}, {} = {}) {
        // console.log('do')
        const {data} = await GetMediaTagList({
            userId: getToken()
        });
        commit('GetMediaTagList', data);
    },
    async GetTagGroupList({commit}, {} = {}) {
        // console.log('do')
        const {data} = await GetTagGroupList({
            userId: getToken()
        });
        commit('GetTagGroupList', data);
    },

    async GetGroupList_ByTag({commit}, {tagIds,key,station='',isCityAnd=false} = {}) {
        // console.log('do')
        const {data} = await GetGroupList_ByTag({
            userId: getToken(),tagIds,key,station,isCityAnd
        });
        commit('GetGroupList_ByTag', data);
    },

    async GetValidStandardProductList({commit}, {stationIds='',saleStatus='',playStatus='',key='',isValid=1,packageIds='',productType=0} = {}) {
        const {data} = await GetProductList({
            userId: getToken(),stationIds,saleStatus,playStatus,key,isValid,packageIds,productType
        });
        // console.log('standard',data)
        commit('GetValidStandardProductList', data);
    },

    async GetStationPositionMediaList({commit}, {stationId=-1,typeIds='',posIds='',key=''} = {}) {
        const {data} = await GetStationPositionMediaList({
            userId: getToken(),stationId,typeIds,posIds,key
        });
        commit('GetStationPositionMediaList', data);
    },

    async GetProductMediaList({commit}, {stationId=-1,typeIds='',posIds='',key=''} = {}) {
        // console.log('bb',typeIds)
        const {data} = await GetProductMediaList({
            userId: getToken(),stationId,typeIds,posIds,key
        });
        commit('GetProductMediaList', data);
    },

    async GetGroupDetail({commit}, {groupId=''} = {}) {
        const {data} = await GetGroupDetail({
            userId: getToken(),groupId
        });
        commit('GetGroupDetail', data);
    },

    async GetPackageMediaList({commit}, {groupId=''} = {}) {
        const {data} = await GetGroupDetail({
            userId: getToken(),groupId
        });
        commit('GetPackageMediaList', data);
    },

    async Media_LedList({commit}, {typeId=1,stationIds,subTypeIds='',statusName='',key} = {}) {
        const {data} = await GetMediaList({
            userId: getToken(),typeId,subTypeIds,stationIds,statusName,key
        });
        commit('Media_LedList', data);
    },
    async Media_DigitalList({commit}, {typeId=3,stationIds,subTypeIds='',key} = {}) {
        const {data} = await GetMediaList({
            userId: getToken(),typeId,stationIds,subTypeIds,key
        });
        commit('Media_DigitalList', data);
    },
    async Media_TrainList({commit}, {typeId=4,stationIds,subTypeIds='',key} = {}) {
        const {data} = await GetMediaList({
            userId: getToken(),typeId,subTypeIds,stationIds,key
        });
        commit('Media_TrainList', data);
    },
    

    async Media_DxList({commit}, {typeId=2,stationIds,subTypeIds='',key} = {}) {
        const {data} = await GetMediaList({
            userId: getToken(),typeId,stationIds,subTypeIds,key
        });
        commit('Media_DxList', data);
    },

    // async Media_AllList({commit}, {typeId=0,stationIds,subTypeIds='',key} = {}) {
    //     const {data} = await GetMediaList({
    //         userId: getToken(),typeId,stationIds,subTypeIds,key
    //     });
    //     commit('Media_AllList', data);
    // },

    async GetProjectSmartList({commit}, {sDate,eDate,brand,industryIds,stationIds,productIds,saleUserId,adVersion} = {}) {
        const {data} = await GetProjectSmartList({
            userId: getToken(),sDate,eDate,brand,industryIds,stationIds,productIds,saleUserId,adVersion
        });
        commit('GetProjectSmartList', data);
    },

    async GetAdVersionList({commit}, {projectId} = {}) {
        const {data} = await GetAdVersionList({
            userId: getToken(),projectId
        });
        commit('GetAdVersionList', data);
    },
    







    async CalculateValue_ProvinceList({commit}, {userId,provinceId,sDate,eDate,stationIds,typeIds,positionIds} = {}) {
        const {data} = await CalculateValue_Province({
            userId: userId || getToken(),provinceId,sDate,eDate,stationIds,typeIds,positionIds
        });
        commit('CalculateValue_ProvinceList', data);
    },
    async CalculateProjectValueList({commit}, {userId,projectId} = {}) {
        const {data} = await CalculateProjectValue({
            userId: userId || getToken(),projectId
        });
        commit('CalculateProjectValueList', data);
    },
    async projectList({commit}, {userId,sDate,eDate,projectName} = {}) {
        const {data} = await GetProjectList({
            userId: userId || getToken(),sDate,eDate,projectName
        });
        commit('projectList', data);
    },
    async projectMediaList({commit}, {projectId} = {}) {
        const {data} = await GetProjectMediaList({
            userId: getToken(),projectId,isDetail:1
        });
        commit('projectMediaList', data);
    },
    async projectMediaList_NotDetail({commit}, {projectId} = {}) {
        const {data} = await GetProjectMediaList({
            userId: getToken(),projectId,isDetail:0
        });
        commit('projectMediaList_NotDetail', data);
    },
    async GetProjectProductMediaList({commit}, {detailId} = {}) {
        const {data} = await GetProjectProductMediaList({
            userId: getToken(),detailId
        });
        commit('GetProjectProductMediaList', data);
    },
    // async stationList({commit}, {userId,sDate='',eDate='',areas='',positions='',types='',stationIds=''} = {}) {
    //     const {data} = await GetStationList({
    //         userId: userId || getToken(),sDate,eDate,areas,positions,types,stationIds
    //     });
    //     commit('stationList', data);
    // },

    async stationDetail({commit}, {userId,stationId} = {}) {
            const {data} = await GetStationDetail({
                userId: userId || getToken(),stationId
            });
            commit('stationDetail', data);
    },

    async indexData({commit}, {userId} = {}) {
        const {data} = await GetIndexData({
            userId: userId || getToken()
        });
        commit('indexData', data);
},

    async DelProject({commit}, {projectId}) {
        console.log('aaa')
        return DelProject({userId: getToken(), projectId});
    },

    async mediaTypeList({commit}, {userId,typeId=1} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('mediaTypeList', data);
    },

    async stationList({commit}, {userId,typeId=23} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('stationList', data);
    },

    async positionList({commit}, {userId,typeId=3} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('positionList', data);
    },

    async packageList({commit}, {userId,typeId=10} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('packageList', data);
    },


    async ageList({commit}, {userId,typeId=11} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('ageList', data);
    },

    async genderList({commit}, {userId,typeId=12} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('genderList', data);
    },

    async targetList({commit}, {userId,typeId=13} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('targetList', data);
    },

    async outComeList({commit}, {userId,typeId=14} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('outComeList', data);
    },

    async areaStationList({commit}, {userId,typeId=2} = {}) {
        const {data} = await DDList({
            userId: userId || getToken(),typeId
        });
        commit('areaStationList', data);
    },

    async juDuanSuoList({commit}, {userId,typeId=31} = {}) {
        const {data} = await DDList({
            userId: userId || getToken(),typeId
        });
        commit('juDuanSuoList', data);
    },

    async AreaProvinceCityList({commit}, {userId,typeId=28} = {}) {
        const {data} = await DDList({
            userId: userId || getToken(),typeId
        });
        commit('AreaProvinceCityList', data);
    },

    async intelligenceAreaList({commit}, {userId,typeId=24} = {}) {
        const {data} = await DDList({
            userId: userId || getToken(),typeId
        });
        commit('intelligenceAreaList', data);
    },

    
    async lujuXianluList({commit}, {userId,typeId=7} = {}) {
        const {data} = await DDList({
            userId: userId || getToken(),typeId
        });
        commit('lujuXianluList', data);
    },

    async lieCheInnerJiaGeLeiXingList({commit}, {userId,typeId=8} = {}) {
        const {data} = await DDListLevel2({
            userId: userId || getToken(),typeId
        });
        commit('lieCheInnerJiaGeLeiXingList', data);
    },
    async lieCheTvJiaGeLeiXingList({commit}, {userId,typeId=9} = {}) {
        const {data} = await DDListLevel2({
            userId: userId || getToken(),typeId
        });
        commit('lieCheTvJiaGeLeiXingList', data);
    },

    async goldenList({commit}, {userId,typeId=25} = {}) {
        const {data} = await DDListLevel2({
            userId: userId || getToken(),typeId
        });
        commit('goldenList', data);
    },
    async categoryList({commit}, {userId,typeId=4} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('categoryList', data);
    },
    async dxTypeList({commit}, {userId,typeId=6} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('dxTypeList', data);
    },

    async productList({commit}, {userId,typeId=5} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('productList', data);
    },

    async setSmartCondition({commit}, data) {
        commit('setSmartCondition', data);
    },

    async ExportStationList({commit}, {sDate,eDate,areas,positions,types,stationIds}) {
        return ExportStationList({
            userId: getToken(),sDate,eDate,areas,positions,types,stationIds
            
        });
    },

    async InnerTrainPriceList({commit}, {userId,mediaType='列车内媒体',trainTypeOrLuJu,priceName,key} = {}) {
        const {data} = await GetTrainPriceList({
            userId: userId || getToken(),mediaType,trainTypeOrLuJu,priceName,key
        });
        commit('InnerTrainPriceList', data);
    },

    async TvTrainPriceList({commit}, {userId,mediaType='列车电视',trainTypeOrLuJu,priceName,key} = {}) {
        const {data} = await GetTrainPriceList({
            userId: userId || getToken(),mediaType,trainTypeOrLuJu,priceName,key
        });
        commit('TvTrainPriceList', data);
    },

    async unreadMessageList({commit}, {sDate, eDate, msgType, force} = {}) {
        if (force) {
            obj = {sDate, eDate, status:2, msgType};
        }
        
        const {data} = await getMessageListByUser({userId: getToken(), ...obj});
        // console.log('laile',data)
        commit('SET_UNREADMESSAGE_LIST', data);
    },

    async readedMessageList({commit}, {sDate, eDate,  msgType, force} = {}) {
        if (force) {
            obj = {sDate, eDate, status:1, msgType};
        }
        const {data} = await getMessageListByUser({userId: getToken(), ...obj});
        commit('SET_READEDMESSAGE_LIST', data);
    },

    async Analisys_Alert({commit}, {userId,sDate, eDate} = {}) {
        const {data} = await Analisys_Alert({
            userId: userId || getToken(),sDate, eDate
        });
        commit('Analisys_Alert', data);
    },

    async BuildCartItem({commit}, {cost,sDate,eDate,times,length,change,curItem,tmpData} = {}) {
        // console.log('budget',budget)
        const {data} = await BuildCartItem({
            userId: getToken(),cost,sDate,eDate,times,length,change,curItem,tmpData
        });
        commit('BuildCartItem', data);
    },

    async GetStationInfo({commit}, {station} = {}) {
        // console.log('budget',budget)
        const {data} = await GetStationInfo({
            userId: getToken(),station
        });
        commit('GetStationInfo', data);
    },

    async BuildCartItem_Multi({commit}, {sDate,eDate,times,length,groupIds} = {}) {
        // console.log('budget',budget)
        const {data} = await BuildCartItem_Multi({
            userId: getToken(),sDate,eDate,times,length,groupIds
        });
        commit('BuildCartItem_Multi', data);
    },

    async AreaList({commit}, {userId,typeId=18} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('AreaList', data);
    },
    async ReportTypeList({commit}, {userId,typeId=26} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('ReportTypeList', data);
    },
    async MediaOwnerList({commit}, {userId,typeId=27} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('MediaOwnerList', data);
    },
    async CityLevelList({commit}, {userId,typeId=21} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('CityLevelList', data);
    },
    async ProvinceList({commit}, {userId,typeId=19} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('ProvinceList', data);
    },
    async ProvinceCityStationList({commit}, {userId,typeId=20} = {}) {
        const {data} = await DDList({
            userId: userId || getToken(),typeId
        });
        commit('ProvinceCityStationList', data);
    },
    // async SmartSuggest({commit}, {budget,discount,tagIds,times,length,sDate,eDate,ip='',cityName=''} = {}) {
    //     const {data} = await SmartSuggest({
    //         uId: getToken(),budget,discount,tagIds,times,length,sDate,eDate,ip,cityName
    //     });
    //     commit('SmartSuggest', data);
    // },
    async SmartSuggest({commit}, {budget,discount,tagIds,times,length,sDate,eDate,ip='',cityName=''} = {}) {
        const {data} = await SmartSuggest({
            uId: getToken(),budget,discount,tagIds,times,length,sDate,eDate,ip,cityName
        });
        commit('SmartSuggest', data);
    },
    async SmartSuggestNew({commit}, {budget,ledPercent,ledDiscount,dxPercent,dxDiscount,lcPercent,lcDiscount,tagIds,times,length,sDate,eDate,ip='',cityName=''} = {}) {
        const {data} = await SmartSuggestNew({
            uId: getToken(),budget,ledPercent,ledDiscount,dxPercent,dxDiscount,lcPercent,lcDiscount,tagIds,times,length,sDate,eDate,ip,cityName
        });
        commit('SmartSuggest', data);
    },
    async GetTeSeTaoCanList({commit}, {} = {}) {
        const {data} = await GetTeSeTaoCanList({
            userId: getToken()
        });
        commit('GetTeSeTaoCanList', data);
    },

    async GetMgrStationList({commit}, {stationIds,key} = {}) {
        const {data} = await GetMgrStationList({
            userId: getToken(),stationIds,key
        });
        // console.log('aaa',data)
        commit('GetMgrStationList', data);
    },

    async stationGradeList({commit}, {userId,typeId=29} = {}) {
        const {data} = await DDSingleList({
            userId: userId || getToken(),typeId
        });
        commit('stationGradeList', data);
    },



}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
