export function createChartData({series,xName=''}) {
    const data = {columns: [xName, ...Object.keys(series[0] ?? {}).filter(item => item !== xName)], rows: series};
    // console.log(data);
    return data;
}

export function createChart1Data({data,title,name}){
    var obj={};
    var xData=[];
    var yData=[];
    data.forEach(ele => {
        xData.push(ele.label);
        yData.push(ele.value);
    });
    obj.title=title;
    obj.name=name;
    obj.xData=xData;
    obj.yData=yData;
    return obj;
    // name:'张雪',
    // xData: ['2020-02', '2020-03', '2020-04', '2020-05'],
    // yData: [30, 132, 80, 134],
}

export function createPieData({data}){
    var obj=[];
    
    data.forEach(ele => {
        obj.push({value:ele.value,name:ele.label});
    });

    return obj;
    // [
    //     { value: 1048, name: 'Search Engine' },
    //     { value: 735, name: 'Direct' },
    //     { value: 580, name: 'Email' },
    //     { value: 484, name: 'Union Ads' },
    //     { value: 300, name: 'Video Ads' }
    //   ]
}

export function createLineData({data}){
    var obj={};
    var xAxisData=[];
    var seriesData=[];
    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    // data: [820, 932, 901, 934, 1290, 1330, 1320],
    // console.log('aa',data)
    data.forEach(ele => {
        xAxisData.push(ele.label);
        seriesData.push(ele.value);
    });
    obj.xAxisData=xAxisData;
    obj.seriesData=seriesData;
    return obj;
    
}


export function setLeve1Check({data}){
    // console.log('data10',data)
    data.forEach(ele => {
        ele.checked=false;
    });
    // console.log('data11',data)
    return data;
}

export function setLeve3Check({data}){
    data.forEach(ele => {
        ele.checked=false;
        ele.description='';
        var i=0
        if (ele.children){
            ele.children.forEach(ele2 => {
                ele2.checked=false;
                var j=0;
                if(ele2.children){
                    ele2.children.forEach(ele3 => {
                        ele3.checked=false;
                        j=j+1;
                    });
                }
                ele2.num=j;
            });
            i=i+1;
        }
        ele.num=i;
    });
    console.log('data2',data)
    return data;
}


export function generateSlideData({data}) {
    
    const parentArray = data.filter(item => !item.parentId).map(item => ({
            ...item,
            url: item.url ? ('/' + item.url) : ''
        })),
        children = data.filter(item => item.parentId).map(item => ({...item, url: item.url ? ('/' + item.url) : ''}));
    children.forEach(child => {
        const parent = parentArray.find(parent => parent.moduleId === child.parentId);
        if (!parent.children) {
            parent.children = [];
        }
        parent.children.push(child);
    })
    // console.log('generateSlideData',parentArray)
    return parentArray;
}

export function generateDepartmentList({data}) {
    const getDepartmentObjFun = (item) => ({value: item.departmentId, label: item.departmentName,});
    const tempArray = [...getRootArray({data}).map(getDepartmentObjFun)];

    function createChildren(parent, data) {
        const childrenArray = getChildrenArray({parentId: parent.value, data}).map(getDepartmentObjFun);
        if (childrenArray.length > 0) {
            parent.children = childrenArray;
            for (let child of parent.children) {
                createChildren(child, data);
            }
        }
    }

    for (let item of tempArray) {
        createChildren(item, data);
    }
    return tempArray;
}

export function generateDepartmentFinalIdArray({departmentId, departmentList}) {
    const queue = [];

    function findTargetId(currentItem, targetId) {
        if (currentItem.value === targetId) {
            return currentItem;
        } else if (currentItem.children && currentItem.children.length > 0) {
            for (let child of currentItem.children) {
                queue.push(child.value);
                const target = findTargetId(child, targetId);
                if (target) {
                    return target;
                } else {
                    queue.pop();
                }
            }
        }
    }

    for (let item of departmentList) {
        queue.push(item.value);
        const target = findTargetId(item, departmentId);
        if (target) {
            return queue;
        } else {
            queue.pop();
        }
    }
    return queue;
}


export function generateDepartmentFinalNameArray({departmentId, departmentList}) {
    const queue = [];

    function findTargetId(currentItem, targetId) {
        if (currentItem.value === targetId) {
            return currentItem;
        } else if (currentItem.children && currentItem.children.length > 0) {
            for (let child of currentItem.children) {
                queue.push(child);
                const target = findTargetId(child, targetId);
                if (target) {
                    return target;
                } else {
                    queue.pop();
                }
            }
        }
    }

    for (let item of departmentList) {
        queue.push(item);
        const target = findTargetId(item, departmentId);
        if (target) {
            return queue;
        } else {
            queue.pop();
        }
    }
    return queue;
}


function findDepartmentObjById({departmentId, departmentList}) {
    function findTargetId(currentItem, targetId) {
        if (currentItem.value === targetId) {
            return currentItem;
        } else if (currentItem.children && currentItem.children.length > 0) {
            for (let child of currentItem.children) {
                const target = findTargetId(child, targetId);
                if (target) {
                    return target;
                }
            }
        }
    }

    for (let item of departmentList) {
        const target = findTargetId(item, departmentId);
        if (target) {
            return target;
        }
    }
}


// const list = generateDepartmentList({data: obj.dt});
//
// console.log(JSON.stringify(generateDepartmentFinalIdArray({departmentId: 6, departmentList: list})));

function getDepartmentObjFun() {
    return (item) => ({value: item.departmentId, label: item.departmentName,});
}

function getChildrenArray({parentId, data}) {
    return data.filter(item => parentId === item.parentId);
}

function getRootArray({data}) {
    return data.filter(item => !item.parentId);
}
