export const userGetters = {
    // sidebar: state => state.user.slideData,

    sidebar:state=>{
        const side = state.user.slideData;
        // console.log('side',side)
        return side;
    },


    sidebarDefaultIndex: state => state.user.sidebarDefaultIndex,
    // userList: state => state.user.userList.concat(state.user.userList).map((item, index) => ({
    //     ...item, num: index + 1,
    //     isAdmin2: item.isAdmin ? '是' : '否', rowTime: item.rowTime.split('T').join(' ')
    // })),
    userList: state => state.user.userList,
    ul:state => state.user.ul,
    departmentList: state => state.user.departmentList,
    // departmentTargetList: state => state.user.departmentTargetList.map(item=>({targetId:item.targetId,target:item.target,year:item.year})),
    // userRoleList: state => state.user.roleList,
    userSettingRoleList: state => state.user.userRoleList,

    // size: state => state.app.size,
    // device: state => state.app.device,
    // visitedViews: state => state.tagsView.visitedViews,
    // cachedViews: state => state.tagsView.cachedViews,
    // token: state => state.user.token,
    // avatar: state => state.user.avatar,
    name: state => state.user.name,
    isAdminCompany:state => state.user.isAdminCompany,
    // introduction: state => state.user.introduction,
    // roles: state => state.user.roles,
    // permission_routes: state => state.permission.routes,
    // errorLogs: state => state.errorLog.logs

    userRoleList:state => state.user.userRoleList,
    roleModuleList:state => state.user.roleModuleList,
    companyRoleList:state => state.user.companyRoleList,
};
