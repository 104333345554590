import {createChart1Data,createPieData,createLineData} from "@/utils/data-generator";
import {toRaw} from 'vue';

import { markRaw } from 'vue'

export const analysisGetters = {   
    // Analysis_Alert: state => state.analysis.Analysis_Alert,

    Analysis_Alert_ByType:state=>{
        // const data = toRaw(state.analysis.Analysis_Alert[0] ?? []);
        const data = markRaw(state.analysis.Analysis_Alert[0] ?? []);
        // console.log('aaaaaaaaaa',state.analysis.Analysis_Alert[0] ?? [],toRaw(state.analysis.Analysis_Alert[0] ?? []), markRaw(state.analysis.Analysis_Alert[0] ?? []))
        return createPieData({data})
    },

    Analysis_Alert_List:state=>{
        const data = markRaw(state.analysis.Analysis_Alert_List[0] ?? []);
        return data;
    },



    // Analisys:state => state.operate.Analisys,

    Analysis_ByMonth:state=>{
        const data = markRaw(state.analysis.Analisys[0] ?? []);
        return createLineData({data})
        return data;
    },

    Analysis_Area:state=>{
        const data = markRaw(state.analysis.Analisys_Sub[1] ?? []);
        return createPieData({data})
    },

    Analysis_Product:state=>{
        const data = markRaw(state.analysis.Analisys_Sub[2] ?? []);
        return createPieData({data})
    },

    Analysis_Category:state=>{
        const data = markRaw(state.analysis.Analisys_Sub[0] ?? []);
        return createPieData({data})
    },

    Analysis_CityStation:state=>{
        const data = markRaw(state.analysis.Analisys_Sub[3] ?? []);
        return data;
    },
    Analysis_CateBrand:state=>{
        const data = markRaw(state.analysis.Analisys_Sub[4] ?? []);
        return data;
    },

    Analysis_Alert_EachStation: state => state.analysis.Analysis_Alert_EachStation,
    DownSaleReport: state => state.analysis.DownSaleReport,
    // Analysis_Alert_List: state => state.analysis.Analysis_Alert_List,

};