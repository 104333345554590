import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const SecurityTokenKey = "Security-Token"
const roleKey = 'Role-Token'

export function getToken() {
  // return Cookies.get(TokenKey) || JSON.parse(localStorage.getItem('loginInfo'))?.userId;
  return Cookies.get(TokenKey);
}
export function getImagePath(){   
  return 'http://adeffect.fusewise-info.com/AdEffectService/UploadBcsFiles/媒体资源照片/';
  //  return 'http://192.168.223.139/AdEffectService/UploadBcsFiles/媒体资源照片/';
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}


export function getSecurityToken() {
  // return Cookies.get(TokenKey) || JSON.parse(localStorage.getItem('loginInfo'))?.userId;
  return Cookies.get(SecurityTokenKey);
}

export function setSecurityToken(token) {
  return Cookies.set(SecurityTokenKey, token)
}

export function removeSecurityToken() {
  return Cookies.remove(SecurityTokenKey)
}

export function getRoleToken() {
  // return Cookies.get(TokenKey) || JSON.parse(localStorage.getItem('loginInfo'))?.userId;
  return Cookies.get(roleKey);
}

export function setRoleToken(token) {
  return Cookies.set(roleKey, token)
}

export function removeRoleToken() {
  return Cookies.remove(roleKey)
}

