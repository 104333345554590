import {toRaw} from 'vue';

import { markRaw } from 'vue'

export const updownGetters = {   
    // Analysis_Alert: state => state.analysis.Analysis_Alert,

    ImportDataToTmp: state => state.updown.ImportDataToTmp,
    // Analysis_Alert_List: state => state.analysis.Analysis_Alert_List,

};