import {dealDownloadFileResponse} from "@/api/file";
import request from "@/utils/request";
import {getToken} from "@/utils/auth";

/**修改基础标签 */
export function SavePrivateProject({
    userId,privateProjectId,projectName,description,files,isPublic,fileTypeId
}) {
    return request({
        url: `/appi/PrivateProject/SavePrivateProject?`,
        data: {
            userId,privateProjectId,projectName,description,files,isPublic,fileTypeId
        }
    })
    // return request({
    //     url: `/appi/PrivateProject/SavePrivateProject?userId=${userId}&privateProjectId=${privateProjectId}&projectName=${projectName}&description=${description}&files=${files}&isPublic=${isPublic}`
    // })
}

export function DelPrivateProject({userId,privateProjectId}) {   
    // console.log("dothis") 
    return request({url: `/appi/PrivateProject/DelPrivateProject?userId=${userId}&privateProjectId=${privateProjectId}`})
}

export function GetPrivateProjectList({userId,key,sDate,eDate,isPublic,fileTypeId}) {   
    // console.log("dothis") 
    return request({url: `/appi/PrivateProject/GetPrivateProjectList?userId=${userId}&key=${key}&sDate=${sDate}&eDate=${eDate}&isPublic=${isPublic}&fileTypeId=${fileTypeId}`})
}


