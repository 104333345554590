
export const surveyGetters = {
    projectQuestions:state => state.survey.projectQuestions,
    projectList:state => state.survey.projectList,
    modelList:state => state.survey.modelList,
    questionTypeList:state => state.survey.questionTypeList,
    provinceCityList:state => state.survey.provinceCityList,
    modelDetail:state => state.survey.modelDetail,
    projectQADetail:state => state.survey.projectQADetail,
    projectQuotaList:state => state.survey.projectQuotaList,
    cateList:state => state.survey.cateList,
    cateList2024:state => state.survey.cateList2024,
    projectAnalisys:state => state.survey.projectAnalisys,
    ProjectAnalisysV2:state => state.survey.ProjectAnalisysV2,
    ProjectAnalisysV4:state => state.survey.ProjectAnalisysV4,
    ProjectAnalisysV5:state => state.survey.ProjectAnalisysV5,
    questionList:state => state.survey.questionList,
    answerList:state => state.survey.answerList,
    colList:state => state.survey.colList,
    projectRatioList:state => state.survey.projectRatioList,
    projectAgentList:state => state.survey.projectAgentList,
};