// import {
//     Analysis_Month_Week,
//     Analysis_Product,
//     Analysis_RepeatBuy,
//     Analysis_Area,
//     Analysis_PV,
//     Analysis_PerPrice,
//     Analysis_Profit,
//     PVExport,
//     SaleAnalysisV2,
//     UserAnalysisV2,
//     exportAnalysisList,
// } from "@/api/analysis";
// import { getToken } from "@/utils/auth";


const state = {
    tagsList: [],
    collapse: false,
    title:'',
};

const mutations = {
    setSysTitle(state,title){
        state.title=title;
    },
    delTagsItem(state, data) {
        state
            .tagsList
            .splice(data.index, 1);
    },
    setTagsItem(state, data) {
        state
            .tagsList
            .push(data)
    },
    clearTags(state) {
        state.tagsList = []
    },
    closeTagsOther(state, data) {
        state.tagsList = data;
    },
    closeCurrentTag(state, data) {
        for (let i = 0, len = state.tagsList.length; i < len; i++) {
            const item = state.tagsList[i];
            if (item.path === data.$route.fullPath) {
                if (i < len - 1) {
                    data
                        .$router
                        .push(state.tagsList[i + 1].path);
                } else if (i > 0) {
                    data
                        .$router
                        .push(state.tagsList[i - 1].path);
                } else {
                    data
                        .$router
                        .push("/");
                }
                state
                    .tagsList
                    .splice(i, 1);
                break;
            }
        }
    },
    // 侧边栏折叠
    hadndleCollapse(state, data) {
        state.collapse = data;
    },
}


const actions = {
    // async SaleAnalysisV2({ commit }, { dataType }) {
    //     const { data } = await SaleAnalysisV2({
    //         userId: getToken(), dataType
    //     });
    //     commit('SaleAnalysisV2', data);
    // },
    
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
