import request from "@/utils/request";
// import {formatSourceDataFromSever} from "@/views/BCSProduct/style-xml-manager";



export function getScreenSet({userId}) {
    return request({url: `/appi/Index/SaleAnalysisTitle?uId=${userId}`})
}

export function Analysis_Month_Week({userId, sDate,eDate}) {
    
    return request({url: `/appi/Analysis/Analysis_Month_Week?uId=${userId}&sDate=${sDate}&eDate=${eDate}`})

    // console.log('aaa')
    // return [{"aa":1,"bb":"a"},{"aa":2,"bb":"b"}];
}
