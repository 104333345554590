import {createRouter, createWebHistory,createWebHashHistory} from "vue-router";
import Home from "../views/Home.vue";
import {getSecurityToken} from '@/utils/auth'

const routes = [
    // {
    //     path: '/',
    //     redirect: '/survey',//'/Index',//'/dashboard'
    // }, 
    {
        path: "/survey/:projectId/:rxsign/:userId/:sign/:agent",
        name: "survey",
        meta: {
            title: 'TEM接触点效益智能管理系统'
        },
        component: () => import (
        /* webpackChunkName: "login" */
        "../views/Survey/survey.vue")
    },
    // {
    //     path: "/bkground",
    //     name: "bkground",
    //     meta: {
    //         title: '我的项目'
    //     },
    //     component: () => import (
    //     /* webpackChunkName: "login" */
    //     "../views/Survey/bkground/projectList.vue")
    // },
    {
        path:'/bkground',
        component:Home,
        children:[
            {
                path: 'projList',
                component: () => import('@/views/Survey/bkground/projectList.vue'),
                name: 'projList',
                meta: {title: '项目列表', icon: 'dashboard', affix: true}
            },
            {
                path: 'model',
                component: () => import('@/views/Survey/bkground/model.vue'),
                name: 'model',
                meta: {title: '问卷模板', icon: 'dashboard', affix: true}
            },
            {
                path: 'modelEdit/:modelId',
                component: () => import('@/views/Survey/bkground/modelEdit.vue'),
                name: 'modelEdit',
                meta: {title: '模板编辑', icon: 'dashboard', affix: true}
            },
            {
                path: 'analisys',
                component: () => import('@/views/Survey/bkground/analisys.vue'),
                name: 'analisys',
                meta: {title: '问卷分析', icon: 'dashboard', affix: true}
            },
        ]
    },
   
    {
        path: "/Index",
        component: Home,
        children: [
            {
                path: "/Index",
                name: "Index",
                meta: {
                    title: '系统首页'
                },
                component: () => import ("@/views/Index")
            },
        ]
    },
    {
        path:'/Report',
        component:Home,
        children:[
            {
                path: 'Index',
                component: () => import('@/views/Report/Index'),
                name: 'Index',
                meta: {title: '报告中心', icon: 'dashboard', affix: true}
            },
        ]
    },
    {
        path:'/Support',
        component:Home,
        children:[
            {
                path: 'Setting',
                component: () => import('@/views/Support/Setting'),
                name: 'Setting',
                meta: {title: '客户服务', icon: 'dashboard', affix: true}
            },
            {
                path: 'Jm',
                component: () => import('@/views/Support/Jm'),
                name: 'Jm',
                meta: {title: '竞媒系统', icon: 'dashboard', affix: true}
            },
            {
                path: 'Xk',
                component: () => import('@/views/Support/Xk'),
                name: 'Xk',
                meta: {title: '销控服务', icon: 'dashboard', affix: true}
            },
        ]
    },
    {
        path: '/Business',
        component: Home,
        children: [
            // {
            //     path: 'Smart',
            //     component: () => import('@/views/Business/Smart'),
            //     name: 'Smart',
            //     meta: {title: '智能预测', icon: 'dashboard', affix: true}
            // },
            {
                path: 'Intelligence',
                component: () => import('@/views/Business/Intelligence'),
                name: 'Intelligence',
                meta: {title: '资源智选', icon: 'dashboard', affix: true}
            },
            {
                path: 'Product',
                component: () => import('@/views/Business/Smart/Product'),
                name: 'Product',
                meta: {title: '特色套餐', icon: 'dashboard', affix: true}
            },
            {
                path: 'StandardProduct',
                component: () => import('@/views/Business/Smart/StandardProduct'),
                name: 'StandardProduct',
                meta: {title: '常态套餐', icon: 'dashboard', affix: true}
            },
            {
                path: 'Custom',
                component: () => import('@/views/Business/Smart/Custom'),
                name: 'Custom',
                meta: {title: '资源检索', icon: 'dashboard', affix: true}
            },
            {
                path: 'Chance',
                component: () => import('@/views/Business/Smart/Chance'),
                name: 'Chance',
                meta: {title: '潜客开发', icon: 'dashboard', affix: true}
            },
            
            // {
            //     path: 'Custom',
            //     component: () => import('@/views/Business/Custom'),
            //     name: 'source',
            //     meta: {title: '自定义预测', icon: 'dashboard', affix: true}
            // },
            {
                path: 'smartResult',
                component: () => import('@/views/Business/SmartResult'),
                name: 'smartResult',
                meta: {title: '推荐结果', icon: 'dashboard', affix: true}
            },
            {
                path: 'Effect',
                component: () => import('@/views/Business/Effect'),
                name: 'Effect',
                meta: {title: '效果评估', icon: 'dashboard', affix: true}
            },
            {
                path: 'ProductPackage',
                component: () => import('@/views/Business/ProductPackage'),
                name: 'ProductPackage',
                meta: {title: '产品包管理', icon: 'dashboard', affix: true}
            },
            {
                path: 'Cart',
                component: () => import('@/views/Business/Cart'),
                name: 'Cart',
                meta: {title: '购物车', icon: 'dashboard', affix: true}
            },
            {
                path: 'Group',
                component: () => import('@/views/Business/Group'),
                name: 'Group',
                meta: {title: '套餐管理', icon: 'dashboard', affix: true}
            },
            {
                path: 'Media_dx',
                component: () => import('@/views/Business/Media_dx'),
                name: 'Media_dx',
                meta: {title: '灯箱媒体管理', icon: 'dashboard', affix: true}
            },
            {
                path: 'Media_led',
                component: () => import('@/views/Business/Media_led'),
                name: 'Media_led',
                meta: {title: 'LED媒体管理', icon: 'dashboard', affix: true}
            },
            {
                path: 'Media_digital',
                component: () => import('@/views/Business/Media_digital'),
                name: 'Media_digital',
                meta: {title: '数字创意灯箱媒体', icon: 'dashboard', affix: true}
            },
            {
                path: 'Media_train',
                component: () => import('@/views/Business/Media_train'),
                name: 'Media_train',
                meta: {title: '列车媒体', icon: 'dashboard', affix: true}
            },
            {
                path: 'StationMgr',
                component: () => import('@/views/Business/StationMgr'),
                name: 'StationMgr',
                meta: {title: '车站管理', icon: 'dashboard', affix: true}
            },
            {
                path: 'Dictionary',
                component: () => import('@/views/Business/Dictionary'),
                name: 'Dictionary',
                meta: {title: '字典维护', icon: 'dashboard', affix: true}
            },
            {
                path: 'MyProject',
                component: () => import('@/views/Business/MyProject'),
                name: 'MyProject',
                meta: {title: '我的项目', icon: 'dashboard', affix: true}
            },            
            {
                path: 'Analisys',
                component: () => import('@/views/Business/Analisys'),
                name: 'Analisys',
                meta: {title: '销售分析', icon: 'dashboard', affix: true}
            },
            
            {
                path: 'PersonCnt',
                component: () => import('@/views/Business/PersonCnt'),
                name: 'PersonCnt',
                meta: {title: '客流量分析', icon: 'dashboard', affix: true}
            },
            {
                path: 'Alert',
                component: () => import('@/views/Business/Alert'),
                name: 'Alert',
                meta: {title: '在刊分析', icon: 'dashboard', affix: true}
            },
            
            // {
            //     path: 'Screen',
            //     component: () => import('@/views/Business/Screen'),
            //     name: 'Screen',
            //     meta: {title: '统计大屏', icon: 'dashboard', affix: true}
            // },
           

            // {
            //     path: 'Media',
            //     component: () => import('@/views/Business/Media'),
            //     name: 'Media',
            //     redirect: 'Media/dx',
            //     meta: {title: '媒体管理', icon: 'dashboard', affix: true},
            //     children: [
            //         {
            //             path: 'dx',
            //             component: () => import('@/views/Business/Media_dx'),
            //             name: 'Media-dx',
            //             meta: {title: '灯箱', icon: 'dashboard', affix: true}
            //         },
            //         // {
            //         //     path: 'szdx',
            //         //     component: () => import('@/views/Operate/Source/szdx'),
            //         //     name: 'Source-szdx',
            //         //     meta: {title: '数字灯箱', icon: 'dashboard', affix: true}
            //         // },    
            //         {
            //             path: 'led',
            //             component: () => import('@/views/Business/Media_led'),
            //             name: 'Media-led',
            //             meta: {title: 'LED', icon: 'dashboard', affix: true}
            //         },                   
            //     ]
            // },
        ]
    },
    {
        path: '/Base',
        component: Home,
        children: [            
            {
                path: 'Label',
                component: () => import('@/views/Base/Label'),
                name: 'Label',
                meta: {title: '标签库', icon: 'dashboard', affix: true}
            },
            {
                path: 'Media',
                component: () => import('@/views/Base/Media'),
                name: 'Media',
                meta: {title: '资源标签', icon: 'dashboard', affix: true}
            },     
            {
                path: 'Weapon',
                component: () => import('@/views/Base/Weapon'),
                name: 'Weapon',
                meta: {title: '武器标签', icon: 'dashboard', affix: true}
            },           
        ]
    },
    {
        path: '/Test',
        component: Home,
        children: [
            {
                path: 'Classify',
                component: () => import('@/views/Test/Classify'),
                name: 'Classify',
                meta: {title: '分类', icon: 'dashboard', affix: true}
            },
            // {
            //     path: 'source',
            //     component: () => import('@/views/Business/source'),
            //     name: 'source',
            //     meta: {title: '资源中心', icon: 'dashboard', affix: true}
            // },
            // {
            //     path: 'smartResult',
            //     component: () => import('@/views/Business/smartResult'),
            //     name: 'smartResult',
            //     meta: {title: '推荐结果', icon: 'dashboard', affix: true}
            // },
            // {
            //     path: 'Effect',
            //     component: () => import('@/views/Business/effect'),
            //     name: 'Effect',
            //     meta: {title: '效果评估', icon: 'dashboard', affix: true}
            // },
        ]
    },

    {
        path: '/System',
        component: Home,
        children: [
            {
                path: 'User',
                component: () => import('@/views/System/User/Index'),
                name: 'User',
                meta: {title: '用户管理', icon: 'dashboard', affix: true}
            },
            // {
            //     path: 'Setting',
            //     component: () => import('@/views/System/Setting/Index'),
            //     name: 'Setting',
            //     meta: {title: '系统设置', icon: 'dashboard', affix: true}
            // },
            {
                path: 'Company',
                component: () => import('@/views/System/Company/Index'),
                name: 'Company',
                meta: {title: '客户管理', icon: 'dashboard', affix: true}
            },
            {
                path: 'Role',
                component: () => import('@/views/System/Role/Index'),
                name: 'Role',
                meta: {title: '角色设置', icon: 'dashboard', affix: true}
            },
        ]
    },

    {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import (
                /* webpackChunkName: "dashboard" */
                "../views/Dashboard.vue")
            },
            {
                path: "/index/:shtId",
                name: "index",
                meta: {
                    title: '首页'
                },
                component: () => import (
                /* webpackChunkName: "dashboard" */
                "../views/Index.vue")
            }
            , {
                path: "/table",
                name: "basetable",
                meta: {
                    title: '表格'
                },
                component: () => import (
                /* webpackChunkName: "table" */
                "../views/BaseTable.vue")
            }, {
                path: "/charts",
                name: "basecharts",
                meta: {
                    title: '图表'
                },
                component: () => import (
                /* webpackChunkName: "charts" */
                "../views/BaseCharts.vue")
            }, {
                path: "/form",
                name: "baseform",
                meta: {
                    title: '表单'
                },
                component: () => import (
                /* webpackChunkName: "form" */
                "../views/BaseForm.vue")
            }, {
                path: "/tabs",
                name: "tabs",
                meta: {
                    title: '消息'
                },
                component: () => import (
                /* webpackChunkName: "tabs" */
                "../views/Tabs.vue")
            }, {
                path: "/donate",
                name: "donate",
                meta: {
                    title: '鼓励作者'
                },
                component: () => import (
                /* webpackChunkName: "donate" */
                "../views/Donate.vue")
            }, {
                path: "/permission",
                name: "permission",
                meta: {
                    title: '权限管理',
                    permission: true
                },
                component: () => import (
                /* webpackChunkName: "permission" */
                "../views/Permission.vue")
            }, {
                path: "/i18n",
                name: "i18n",
                meta: {
                    title: '国际化语言'
                },
                component: () => import (
                /* webpackChunkName: "i18n" */
                "../views/I18n.vue")
            }, {
                path: "/upload",
                name: "upload",
                meta: {
                    title: '上传插件'
                },
                component: () => import (
                /* webpackChunkName: "upload" */
                "../views/Upload.vue")
            }, {
                path: "/icon",
                name: "icon",
                meta: {
                    title: '自定义图标'
                },
                component: () => import (
                /* webpackChunkName: "icon" */
                "../views/Icon.vue")
            }, {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import (/* webpackChunkName: "404" */
                '../views/404.vue')
            }, {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import (/* webpackChunkName: "403" */
                '../views/403.vue')
            }
        ]
    }, 

    {
        path: '/Business/Screen',
        name: "Screen",
        component: () => import('@/views/Business/Screen'),
        meta: {title: '统计大屏', affix: true}
    },

    {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
        /* webpackChunkName: "login" */
        "../views/Login.vue")
    }, 
    // {
    //     path: "/login2",
    //     name: "Login2",
    //     meta: {
    //         title: '登录'
    //     },
    //     component: () => import (
    //     /* webpackChunkName: "login" */
    //     "../views/Login2.vue")
    // },
    {
        path: "/reset",
        name: "reset",
        meta: {title: '重置密码'},
        component: () => import ("../views/reset.vue")
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    // history: createWebHistory(process.env.BASE_URL),
    // mode: 'hash',
    routes
});

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | TEM接触点效益智能管理系统`;
    const role = localStorage.getItem('ms_username');
    
    var token = getSecurityToken();
    // console.log('to.meta.permission',to.meta.permission,role,loginInfo)
    
    // console.log('aaaaaa',to.path,to.meta.permission)
    if ((!role || !token) && (to.path !== '/login' && to.path !== '/login2' && to.path.indexOf("/survey") === -1 )) {//to.path !== '/survey'
        console.log('1')
        next('/login');
    } else if (to.meta.permission) {
        console.log('2')
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        role === 'admin'
            ? next()
            : next('/403');
    } else {
        console.log('3')
        next();
    }
});

export default router;