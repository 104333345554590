import {dealDownloadFileResponse} from "@/api/file";
import request from "@/utils/request";
import {getToken} from "@/utils/auth";

// import {formatSourceDataFromSever} from "@/views/Operate/Content/style-xml-manager";
// import {formatDateFromSeverDouble} from "@/utils/tools";

/**
 * 获取多种级联列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function DDList({userId,typeId=1}) {    
    // return request({url: `/appi/Operate/DDList?userId=${userId}&typeId=${typeId}`})
    return request({url: `/appi/Operate/DDList_Level3?userId=${userId}&typeId=${typeId}`})
}

/**
 * 获取2级联列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function DDListLevel2({userId,typeId=1}) {    
    // return request({url: `/appi/Operate/DDList?userId=${userId}&typeId=${typeId}`})
    return request({url: `/appi/Operate/DDList_Level2?userId=${userId}&typeId=${typeId}`})
}

/**
 * 获取列车价格列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetTrainPriceList({userId,mediaType,trainTypeOrLuJu,priceName,key}) {    
    // return request({url: `/appi/Operate/DDList?userId=${userId}&typeId=${typeId}`})
    return request({url: `/appi/Operate/GetTrainPriceList?userId=${userId}&mediaType=${mediaType}&trainTypeOrLuJu=${trainTypeOrLuJu}&priceName=${priceName}&key=${key}`})
}


// /**
//  * 获取多种级联列表
//  * @param userId
//  * @param key
//  * @returns {AxiosPromise}
//  */
//  export function GetGroupandSimpleMedia({userId,stationId}) {    
//     return request({url: `/appi/Operate/GetGroupandSimpleMedia?userId=${userId}&stationId=${stationId}`})
// }

// /**
//  * 获取资源明细
//  * @param userId
//  * @param key
//  * @returns {AxiosPromise}
//  */
//  export function GetMediaDetail({userId,mediaId}) {    
//     return request({url: `/appi/Operate/GetMediaDetail?userId=${userId}&mediaId=${mediaId}`})
// }

/**
 * 获取资源价格
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetPlanPrice({userId,sDate,eDate,length,times,ledDisCount,dxDisCount,groupId,haveSelectListIds}) {    
    // return request({url: `/appi/Operate/GetPlanPrice?userId=${userId}&sDate=${sDate}&eDate=${eDate}&length=${length}&times=${times}&ledDisCount=${ledDisCount}&dxDisCount=${dxDisCount}&groupId=${groupId}`})
    return request({
        url: `/appi/Operate/GetPlanPrice?`,
        data: {
            userId,sDate,eDate,length,times,ledDisCount,dxDisCount,groupId,haveSelectListIds
        }
    })
}

/**
 * 获取资源价格
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetPlanPrice_ByCost({userId,sDate,eDate,times,disCount,cost}) {    
    return request({url: `/appi/Operate/GetPlanPrice_ByCost?userId=${userId}&sDate=${sDate}&eDate=${eDate}&times=${times}&disCount=${disCount}&cost=${cost}`})
    // return request({
    //     url: `/appi/Operate/GetPlanPrice_ByCost?`,
    //     data: {
    //         userId,sDate,eDate,times,disCount,cost
    //     }
    // })
}

/**
 * 获取资源价格
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function GetPlanPrice_ByGroupIds({userId,groupIds,sDate,eDate,times,length,zuShu=0,disCount}) {    
    return request({url: `/appi/Operate/GetPlanPrice_ByGroupIds?userId=${userId}&groupIds=${groupIds}&sDate=${sDate}&eDate=${eDate}&times=${times}&length=${length}&disCount=${disCount}&zuShu=${zuShu}`})
}

/**
 * 获取套餐列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetGroupList({userId,stationIds,saleStatus,playStatus,typeIds,ptIds,key,belowGroupId,exceptIds,isAll}) {    
    // return request({url: `/appi/Operate/GetGroupList?userId=${userId}&stationId=${stationId}&typeIds=${typeIds}&key=${key}&ptIds=${ptIds}&belowGroupId=${belowGroupId}&exceptIds=${exceptIds}&groupLevel=1&isValid=0`})
    return request({
        url: `/appi/Operate/GetGroupList?`,
        data: {
            userId,stationIds,saleStatus,playStatus,typeIds,key,ptIds,belowGroupId,exceptIds,groupLevel:1,isValid:0,isAll
        }
    })

}

/**
 * 获取套餐列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetProductList({userId,stationIds,saleStatus,playStatus,key='',isValid,packageIds,productType}) {    
    // return request({url: `/appi/Operate/GetGroupList?userId=${userId}&stationId=${stationId}&typeIds=&key=${key}&groupLevel=2&isValid=${isValid}`})
    return request({
        url: `/appi/Operate/GetGroupList?`,
        data: {
            userId,stationIds,saleStatus,playStatus,typeIds:'',key,ptIds:'',belowGroupId:'',exceptIds:'',groupLevel:2,isValid,packageIds,productType
        }
    })
}

/**
 * 获取套餐列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetGroupList_ByTag({userId,tagIds,key,station='',isCityAnd=false}) {    
    // return request({url: `/appi/Operate/GetGroupList?userId=${userId}&stationId=${stationId}&typeIds=${typeIds}&key=${key}&ptIds=${ptIds}&belowGroupId=${belowGroupId}&exceptIds=${exceptIds}&groupLevel=1&isValid=0`})
    return request({
        url: `/appi/Operate/GetGroupList_ByTag?`,
        data: {
            userId,tagIds,key,station,isCityAnd
        }
    })

}

/**
 * 获取产品包列表,且带XML购物车格式数据
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetCartData({userId,sDate,eDate,times,length,packageIds,typeId,sids,tids}) {    
    return request({url: `/appi/Operate/GetCartData?userId=${userId}&sDate=${sDate}&eDate=${eDate}&times=${times}&length=${length}&packageIds=${packageIds}&typeId=${typeId}&sids=${sids}&tids=${tids}`})
    // return request({
    //     url: `/appi/Operate/GetCartData?`,
    //     data: {
    //         userId,sDate,eDate,times,packageIds
    //     }
    // })
}

/**
 * 获取点位标签
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetMediaTagList({userId}) {    
    return request({url: `/appi/Operate/GetMediaTagList?userId=${userId}`})
    // return request({
    //     url: `/appi/Operate/GetCartData?`,
    //     data: {
    //         userId,sDate,eDate,times,packageIds
    //     }
    // })
}

/**
 * 获取标签组合Tag
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetTagGroupList({userId}) {    
    return request({url: `/appi/Operate/GetTagGroupList?userId=${userId}`})
    // return request({
    //     url: `/appi/Operate/GetCartData?`,
    //     data: {
    //         userId,sDate,eDate,times,packageIds
    //     }
    // })
}


/**
 * 获取车站、位置下的资源列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetStationPositionMediaList({userId,stationId,typeIds,posIds,key}) {    
    return request({url: `/appi/Operate/GetStationPositionMediaList?userId=${userId}&stationId=${stationId}&typeIds=${typeIds}&posIds=${posIds}&key=${key}`})
}

/**
 * 建立产品包时获取车站、位置下的资源套餐+资源列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetProductMediaList({userId,stationId,typeIds,posIds,key}) {    
    return request({url: `/appi/Operate/GetProductMediaList?userId=${userId}&stationId=${stationId}&typeIds=${typeIds}&posIds=${posIds}&key=${key}`})
}


/**
 * 获取套餐明细
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetGroupDetail({userId,groupId}) {    
    return request({url: `/appi/Operate/GetGroupDetail?userId=${userId}&groupId=${groupId}`})
}

/**项目保存 */
export function SaveProjectSmart({
    userId,projectId,projectName,projectDescript,cateId, brand,budget,cpm,frequency,personCnt,reachRate,ledDiscount,dxDiscount,lcDiscount,weaponId,condi
}) {
    return request({
        url: `/appi/Operate/SaveProjectSmart?`,
        data: {
            userId,projectId,projectName,projectDescript,cateId, brand,budget,cpm,frequency,personCnt,reachRate,ledDiscount,dxDiscount,lcDiscount,weaponId,condi
        }
    })
}

export function DelProjectSmart({userId,projectId}) {   
    console.log("dothis") 
    return request({url: `/appi/Operate/DelProjectSmart?userId=${userId}&projectId=${projectId}`})
}




export function GetProjectSmartList({userId,sDate,eDate,brand,industryIds,stationIds,productIds,saleUserId,adVersion}) {    
    return request({url: `/appi/Operate/GetProjectSmartList?userId=${userId}&sDate=${sDate}&eDate=${eDate}&brand=${brand}&industryIds=${industryIds}&stationIds=${stationIds}&productIds=${productIds}&saleUserId=${saleUserId}&adVersion=${adVersion}`})
}


/**保存套餐 */
export function SaveGroup({
    userId,groupId,typeId, name,mediaIds,cost15,cost10,cost,remark,mediaGrade
}) {
    return request({
        url: `/appi/Operate/SaveGroup?`,
        data: {
            userId,groupId,typeId, name,mediaIds,cost15,cost10,cost,remark,mediaGrade
        }
    })
}

/**保存产品包 */
export function SaveProductGroup({
    userId,groupId, name,packageNames,groupIds,cost,cost10,cost15,saleSDate,saleEDate,playSDate,playEDate,length,times,count,remark,img}) {
    return request({
        url: `/appi/Operate/SaveProductGroup?`,
        data: {
            userId,groupId, name,packageNames,groupIds,cost,cost10,cost15,saleSDate,saleEDate,playSDate,playEDate,length,times,count,remark,img
        }
    })
}


/**
 * 套餐停用、启用
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function SetGroupStatus({userId,groupId}) {    
    return request({url: `/appi/Operate/SetGroupStatus?userId=${userId}&groupId=${groupId}`})
}

/**
 * 获取媒体资源列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetMediaList({userId,typeId,stationIds,subTypeIds,statusName,key}) {    
    return request({url: `/appi/Operate/GetMediaList?userId=${userId}&typeId=${typeId}&stationIds=${stationIds}&subTypeIds=${subTypeIds}&statusName=${statusName}&key=${key}`})
}

/**
 * 保存上传的版本
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function SaveAdVersion({userId,projectId,versionId,versionName,sample}) {    
    return request({url: `/appi/Operate/SaveAdVersion?userId=${userId}&projectId=${projectId}&versionId=${versionId}&versionName=${versionName}&sample=${sample}`})
}

/**
 * 获取项目广告素材列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetAdVersionList({userId,projectId}) {    
    return request({url: `/appi/Operate/GetAdVersionList?userId=${userId}&projectId=${projectId}`})
}

/**
 * 删除项目广告素材
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function DelAdVersion({userId,versionId}) {    
    return request({url: `/appi/Operate/DelAdVersion?userId=${userId}&versionId=${versionId}`})
}

/**
 * 旧版
 * @param {*} flag  1:excel  2:ppt 
 * @returns 
 */
export function ProjectExport({flagId,userId,reportUser, sDate,eDate,brand,cost,frequency,personCnt,cpm,medias}) {
    return request({url: `/appi/Operate/ProjectExport?`
                    ,data: {
                        flagId,userId,reportUser, sDate,eDate,brand,cost,frequency,personCnt,cpm,medias
                    }
                    ,method:'post'
                    ,responseType: 'blob'})
            .then(res => {
        console.log(res);
        return dealDownloadFileResponse({res});
    })
   
}


//导出产品包
export function ExportPackage({userId,groupId, flagId}) {
    return request({url: `/appi/Operate/ExportPackage?userId=${userId}&groupId=${groupId}&flagId=${flagId}`,method:'get',
        responseType: 'blob'}).then(res => {
        console.log(res);
        return dealDownloadFileResponse({res});
    })
   
}


/**
 * 新版 导出项目PPT
 * @param {*} flag  
 * @returns 
 */
//  export function ProjectExportByIdOld({userId,flagId, projectId=''}) {
//     return request({url: `/appi/Operate/ProjectExportById?userId=${userId}&flagId=${flagId}&projectId=${projectId}`,method:'get',
//         responseType: 'blob'}).then(res => {
//         console.log(res);
//         return dealDownloadFileResponse({res});
//     })
// }
export function ProjectExportById({userId,flagId,projectId,items, golds=''}) {

    if (flagId==1){
        return request({url: `/appi/Operate/ProjectExportById?userId=${userId}&flagId=${flagId}&projectId=${projectId}`,method:'get',
            responseType: 'blob'}).then(res => {
            console.log(res);
            return dealDownloadFileResponse({res});
        })
    }else{    
        return request({url: `/appi/Operate/ProjectExportByIdV3?userId=${userId}&projectId=${projectId}&items=${items}&golds=${golds}`,method:'get',
            responseType: 'blob'}).then(res => {
            console.log(res);
            return dealDownloadFileResponse({res});
        })
    }
}

/**
 * 新版 导出项目列表
 * @param {*} flag  1:excel  2:ppt(原简单格式)   3 纯永达格式
 * @returns 
 */
 export function ProjectListExport({userId,projectIds}) {
    return request({url: `/appi/Operate/ProjectListExport?userId=${userId}&projectIds=${projectIds}`,method:'get',
        responseType: 'blob'}).then(res => {
        console.log(res);
        return dealDownloadFileResponse({res});
    })
}





// /**
//  * 导出新版项目
//  * @param userId
//  * @param key
//  * @returns {AxiosPromise}
//  */
//  export function ExportStationList({userId,sDate,eDate,areas,positions,types,stationIds}) {
//     return request({
//         url: `/appi/Operate/ExportStationList?userId=${userId}&sDate=${sDate}&eDate=${eDate}&areas=${areas}&positions=${positions}&types=${types}&stationIds=${stationIds}`,
//         method: 'get',
//         responseType: 'blob'
//     }).then(res => {
//         console.log(res);
//         return dealDownloadFileResponse({res});
//     })
// }






/**
 * 获取单级联列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function DDSingleList({userId,typeId}) {    
    return request({url: `/appi/Operate/DDSingleList?userId=${userId}&typeId=${typeId}`})
}


/**
 * 计算媒体价值-总体
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function CalculateValue({userId,sDate,eDate,stationIds='',typeIds='',positionIds=''}) {    
    return request({url: `/appi/Operate/CalculateValue?userId=${userId}&sDate=${sDate}&eDate=${eDate}&stationIds=${stationIds}&typeIds=${typeIds}&positionIds=${positionIds}`})
}

/**
 * 计算媒体价值-总体
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function CalculateValueNew({userId,sDate,eDate,stationIds='',typeIds='',positionIds='',budget}) {    
    return request({url: `/appi/Operate/CalculateValueNew?userId=${userId}&sDate=${sDate}&eDate=${eDate}&stationIds=${stationIds}&typeIds=${typeIds}&positionIds=${positionIds}&budget=${budget}`})
}


export function CalculateMulti({
    userId,brand,budget, condi
}) {
    console.log("ssdss")
    return request({
        url: `/appi/Operate/CalculateMulti?qno=`+Math.random(),
        data: {
            userId: userId,
            brand,
            budget,
            condi
        }
    })
}


/**
 * 获取项目媒体清单
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetProjectMediaList({userId,projectId,isDetail}) {    
    return request({url: `/appi/Operate/GetProjectMediaList?userId=${userId}&projectId=${projectId}&isDetail=${isDetail}`})
}

/**
 * 获取项目媒体清单
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetTeSeTaoCanList({userId}) {    
    return request({url: `/appi/Operate/GetTeSeTaoCanList?userId=${userId}`})
}


/**
 * 获取项目产品包中可选资源和已选资源及改选的资源
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetProjectProductMediaList({userId,detailId}) {    
    return request({url: `/appi/Operate/GetProjectProductMediaList?userId=${userId}&detailId=${detailId}`})
}


// export function CalculateMultiSmart({
//     userId,brand,budget, condi
// }) {
//     console.log("ssdss")
//     return request({
//         url: `/appi/Operate/CalculateMultiSmart?qno=`+Math.random(),
//         data: {
//             userId: userId,
//             brand,
//             budget,
//             condi
//         }
//     })
// }

export function CalculateMultiSmart({
    userId,ip,cityName,budget,ledDiscount,dxDiscount,lcDiscount,condi
}) {
    console.log("ssdss")
    return request({
        url: `/appi/Operate/CalculateMultiSmart?qno=`+Math.random(),
        data: {
            userId: userId,
            ip,
            cityName,
            budget,ledDiscount,dxDiscount,lcDiscount,
            condi
        }
    })
}


export function CalculateMultiSmart_WithoutBudget({
    userId,ip,cityName,ledDiscount,dxDiscount,lcDiscount,condi
}) {
    console.log("ssdss")
    return request({
        url: `/appi/Operate/CalculateMultiSmart_WithoutBudget?qno=`+Math.random(),
        data: {
            userId: userId,
            ip,
            cityName,
            ledDiscount,dxDiscount,lcDiscount,
            condi
        }
    })
}


/**
 * 计算媒体价值-分省份
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function CalculateValue_Province({userId,provinceId,sDate,eDate,stationIds='',typeIds='',positionIds=''}) {    
    return request({url: `/appi/Operate/CalculateValue_Province?userId=${userId}&provinceId=${provinceId}&sDate=${sDate}&eDate=${eDate}&stationIds=${stationIds}&typeIds=${typeIds}&positionIds=${positionIds}`})
}

/**
 * 计算媒体价值-总体
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function CalculateProjectValue({userId,projectId}) {    
    return request({url: `/appi/Operate/CalculateProjectValue?userId=${userId}&projectId=${projectId}`})
}


/**
 * 项目保存
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function SaveProject({userId,projectName,sDate,eDate,stationIds='',typeIds='',positionIds='',budget}) {    
    return request({url: `/appi/Operate/SaveProject?userId=${userId}&projectName=${projectName}&sDate=${sDate}&eDate=${eDate}&stationIds=${stationIds}&typeIds=${typeIds}&positionIds=${positionIds}&budget=${budget}`})
}

/**
 * 获取项目列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetProjectList({userId,sDate,eDate,projectName=''}) {    
    return request({url: `/appi/Operate/GetProjectList?userId=${userId}&sDate=${sDate}&eDate=${eDate}&projectName=${projectName}`})
}

/**
 * 获取车站列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetStationList({userId,sDate,eDate,areas,positions,types,stationIds}) {    
    return request({url: `/appi/Operate/GetStationList?userId=${userId}&sDate=${sDate}&eDate=${eDate}&areas=${areas}&positions=${positions}&types=${types}&stationIds=${stationIds}`})
}

/**
 * 获取车站明细
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetStationDetail({userId,stationId}) {    
    return request({url: `/appi/Operate/GetStationDetail?userId=${userId}&stationId=${stationId}`})
}

/**
 * 获取首页统计数据
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetIndexData({userId}) {    
    return request({url: `/appi/Operate/GetIndexData?userId=${userId}`})
}


/**
 * 删除项目
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function DelProject({userId,projectId}) {    
    return request({url: `/appi/Operate/DelProject?userId=${userId}&projectId=${projectId}`})
}

/**
 * 导出车站
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function ExportStationList({userId,sDate,eDate,areas,positions,types,stationIds}) {
    return request({
        url: `/appi/Operate/ExportStationList?userId=${userId}&sDate=${sDate}&eDate=${eDate}&areas=${areas}&positions=${positions}&types=${types}&stationIds=${stationIds}`,
        method: 'get',
        responseType: 'blob'
    }).then(res => {
        console.log(res);
        return dealDownloadFileResponse({res});
    })
}

export function getMessageListByUser({userId, sDate = '', eDate = '', status = 0, msgType = 0}) {
    return request({url: `/appi/Operate/MessageListByUser?uId=${userId}&sDate=${sDate}&eDate=${eDate}&status=${status}&msgType=${msgType}`});
}

export function setMessageRead({userId,msgItemId}) {
    return request({url: `/appi/Operate/MessageSetRead?uId=${userId}&msgItemId=${msgItemId}`});
}



export function BuildCartItem({
    userId,cost,sDate,eDate,times,length,change,curItem,tmpData
}) {
    // console.log("ssdss")
    return request({
        url: `/appi/Operate/BuildCartItem?qno=`+Math.random(),
        data: {
            userId: userId,
            cost,
            sDate,
            eDate,times,length,
            change,curItem,tmpData
        }
    })
}

/**
 * 获取点位标签
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function GetStationInfo({userId,station}) {    
    return request({url: `/appi/Operate/GetStationInfo?userId=${userId}&station=${station}`})
    // return request({
    //     url: `/appi/Operate/GetCartData?`,
    //     data: {
    //         userId,sDate,eDate,times,packageIds
    //     }
    // })
}


/**
 * 构造多资源（产品包、套餐）的购物车数据
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function BuildCartItem_Multi({userId,sDate,eDate,times,length,groupIds}) {    
    // return request({url: `/appi/Operate/BuildCartItem_Multi?userId=${userId}&station=${station}`})
    return request({
        url: `/appi/Operate/BuildCartItem_Multi?`,
        data: {
            userId,sDate,eDate,times,length,groupIds
        }
    })
}


/**
 * 设置到期时间
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function SetExpireDate({userId,mids,date,type}) {    
    return request({url: `/appi/Operate/SetExpireDate?userId=${userId}&mids=${mids}&date=${date}&type=${type}`})
}


/**
 * 获取推荐资源及效果
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function SmartSuggest({uId,budget,discount,tagIds,times,length,sDate,eDate,ip,cityName}) {    
    // return request({url: `/appi/Operate/GetPlanPrice?userId=${userId}&sDate=${sDate}&eDate=${eDate}&length=${length}&times=${times}&ledDisCount=${ledDisCount}&dxDisCount=${dxDisCount}&groupId=${groupId}`})
    return request({
        url: `/appi/Operate/SmartSuggest?`,
        data: {
            uId,budget,discount,tagIds,times,length,sDate,eDate,ip,cityName
        }
    })
}
export function SmartSuggestNew({uId,budget,ledPercent,ledDiscount,dxPercent,dxDiscount,lcPercent,lcDiscount,tagIds,times,length,sDate,eDate,ip,cityName}) {    
    // return request({url: `/appi/Operate/GetPlanPrice?userId=${userId}&sDate=${sDate}&eDate=${eDate}&length=${length}&times=${times}&ledDisCount=${ledDisCount}&dxDisCount=${dxDisCount}&groupId=${groupId}`})
    return request({
        url: `/appi/Operate/SmartSuggestNew?`,
        data: {
            uId,budget,ledPercent,ledDiscount,dxPercent,dxDiscount,lcPercent,lcDiscount,tagIds,times,length,sDate,eDate,ip,cityName
        }
    })
}

/**
 * 修改资源
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function UpdateMedia({userId,mediaId,positionId,stationId,typeId,sub1TypeId,sub2TypeId,mediaCode,address,areaPercent,adSize,adArea,mediaImg,remark,expireDate,statusName}) {    
    return request({
        url: `/appi/Operate/UpdateMedia?`,
        data: {
            userId,mediaId,positionId,stationId,typeId,sub1TypeId,sub2TypeId,mediaCode,address,areaPercent,adSize,adArea,mediaImg,remark,expireDate,statusName
        }
    })
}

/**
 * 设置状态
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function UpdateMedia_isDel({userId,mediaIds}) {    
    return request({url: `/appi/Operate/UpdateMedia_isDel?userId=${userId}&mediaIds=${mediaIds}`})
}

/**
 * 设置说明
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function SetMediaRemark({userId,mids,remark}) {    
    return request({url: `/appi/Operate/SetMediaRemark?userId=${userId}&mids=${mids}&remark=${remark}`})
}


/**
 * 设置状态
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function SetMediaStatus({userId,mids,statusName}) {    
    return request({url: `/appi/Operate/SetMediaStatus?userId=${userId}&mids=${mids}&statusName=${statusName}`})
}


/**同步套餐 */
export function Sync_UpdatePackageAndProductStatus({userId}) {
    return request({url: `/appi/Operate/Sync_UpdatePackageAndProductStatus?userId=${userId}`})
}

/**同步套餐 */
export function DelGroup({userId,groupId}) {
    return request({url: `/appi/Operate/DelGroup?userId=${userId}&groupId=${groupId}`})
}


/**************************************车站维护开始 */
export function GetMgrStationList({userId,stationIds,key}) {
    return request({url: `/appi/Operate/GetMgrStationList?userId=${userId}&stationIds=${stationIds}&key=${key}`})
}

export function SaveStation({grade,cityId,station,img1,img3,describe1,describe3,describe4,describe5,describe6,describe7,describe8,describe9,describe10,zcpmimgs,coverCityIds,lines}) {   
    return request({
        url: `/appi/Operate/SaveStation?`,
        data: {
            userId:getToken(),grade,cityId,station,img1,img3,describe1,describe3,describe4,describe5,describe6,describe7,describe8,describe9,describe10,zcpmimgs,coverCityIds,lines
        }
    })
}

export function DelStation({userId,stationId}) {
    return request({url: `/appi/Operate/DelStation?userId=${userId}&stationId=${stationId}`})
}


/**************************************车站维护结束 */


///导出媒体资源
export function GetExportMediaList({userId,stationIds,typeId,key}) {
    return request({url: `/appi/Operate/GetExportMediaList?userId=${userId}&stationIds=${stationIds}&typeId=${typeId}&key=${key}`
                    
                    ,method:'post'
                    ,responseType: 'blob'})
            .then(res => {
        console.log(res);
        return dealDownloadFileResponse({res});
    })
   
}