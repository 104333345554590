import request from '@/utils/request'
// import user from "@/store/modules/user";
// import {dealDownloadFileResponse} from "@/api/file";


export function AdminCompanyList({userId, key = ''}) {
    return request({
        url: `/appi/Company/AdminCompanyList?userId=${userId}&key=${key}`
    })
}
