import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import installElementPlus from './plugins/element'
import ElementPlus from 'element-plus';
import VCharts from 'v-charts';
// import MButton from 'vue-m-button';
import './assets/css/icon.css';
// import dataV from '@jiaminghi/data-view';
// import './directive.js';
import dataV from '@jiaminghi/data-view';
// import Vant from 'vant';
// import {Button} from 'vant';
// import 'vant/lib/index.css'


// console.log('Vant',Vant)

const app = createApp(App)
installElementPlus(app)
app
    .use(ElementPlus, { size: 'small', zIndex: 3000 })
    .use(store)
    .use(router)
    .use(VCharts)
    .use(dataV)
    // .use(MButton)
    // .use(dataV)
    // .use(Vant)
    // .use(Button)
    // .component('Vant', Vant)
    // .component('Button', Button)
    // .use(Sidebar)
    // .use(Grid)
    .mount('#app')

global.vm = app;