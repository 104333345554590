import axios from 'axios'
// import {Message} from 'element-plus'
import store from '@/store'
import {getToken,getSecurityToken,removeSecurityToken,removeToken} from '@/utils/auth'
// import {createRouter, createWebHistory} from "vue-router";
import router from '../router'

import {ElMessage} from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

// create an axios instance
const service = axios.create({
  baseURL: '/SurveyService', 
  // baseURL: '', 
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 6000000, // request timeout
  method: 'post'
})

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// });

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    // if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation

      config.headers['X-Token'] = getToken()
      console.log('1')
      var secu = getSecurityToken();
      // if (secu){
      //   console.log('2')
        config.headers['TokenValue'] = getToken()//secu;
      //   console.log('3')
      // }
      
    // }
    // console.log('config',config)
    return config
  },
  error => {
    // do something with request error
    // console.log('err',error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  
  response => {
      // console.log('getSecurityToken',getSecurityToken())
        // console.log('request response', response);        
        const {status, data, config} = response;
        // console.log('response data', data);
        // if (data.Result == -9999999){   //服务端拒绝访问：你没有权限?，或者掉线了
        //   const message = data.Msg ?? '服务端拒绝访问：没有权限';
        //   ElMessage({
        //       message,
        //       type: 'error',
        //       duration: 5 * 1000
        //   });
        //   removeSecurityToken();
        //   removeToken();
        //   return Promise.reject(new Error(message));
        // }
       
        if (config.responseType !== 'blob') {
            if (data.Result !== 1) {
                const message = data.Msg ?? '服务器异常，请稍后重试';
                ElMessage({
                    message,
                    type: 'error',
                    duration: 5 * 1000
                });
                return Promise.reject(new Error(message));
            } else {
                
                // if (data.token){
                //   // console.log('data.token',data.token)
                //   setSecurityToken(data.token);
                //   console.log('getSecurityToken',getSecurityToken())
                // }
                return {data: data.dt || data.Tag || data.dts || response, code: data.Result,token:data.token};
            }
        } else {
            return {data: response}
        }

  },
  error => {
    console.log('err1111' ,error)
    // console.log('err' , error.message,error.response) // for debug
    if (error.response.status == 403){
      // console.log('dd')
      removeSecurityToken();
      removeToken();
      ElMessage({
        message: '登录超时，请重新登录',
        type: 'error',
        duration: 5 * 1000
      })
      router.replace({ 
        path: '/login' // 到登录页重新获取token 
      })
      // this.$router.push('/login');
    }else{
      ElMessage({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service

export const baseUrl = 'http://hfweb.fusewise-info.com/SurveyService';
// export const fileBaseUrl = baseUrl + '/UploadFiles/';
export const fileBaseUrl = baseUrl + '/UploadBCSFiles/';