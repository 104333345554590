import {getToken} from "@/utils/auth";
// 
// import {formatSourceDataToSever} from "@/views/Operate/Content/style-xml-manager";
import {
    Analysis_Alert,Analysis_Alert_List,Analisys,Analisys_Sub,Analysis_Alert_EachStation,DownSaleReport
} from "@/api/analysis";
// import {formatSourceDataToSever} from "@/views/BCSProduct/style-xml-manager";

const state = {
    Analysis_Alert:[],
    Analysis_Alert_List:[],
    Analysis_Alert_EachStation:[],

    Analisys:{},
    Analisys_Sub:{},
    DownSaleReport:{},
};

const mutations = {    

    Analysis_Alert: (state, list) => {
        state.Analysis_Alert = list;
    },

    Analysis_Alert_List: (state, list) => {
        state.Analysis_Alert_List = list;
    },
    Analysis_Alert_EachStation: (state, list) => {
        state.Analysis_Alert_EachStation = list;
    },


    Analisys:(state, list) => {
        state.Analisys = list;
    },
    Analisys_Sub:(state, list) => {
        state.Analisys_Sub = list;
    },

    DownSaleReport:(state, list) => {
        state.DownSaleReport = list;
    },


}

let obj = {};

const actions = {


    async Analysis_Alert({commit}, {userId,sDate, eDate,typeIds,rateType} = {}) {
        const {data} = await Analysis_Alert({
            userId: userId || getToken(),sDate, eDate,typeIds,rateType
        });
        // console.log('Analysis_Alert',data[0])
        commit('Analysis_Alert', data);
    },

    async Analysis_Alert_List({commit}, {userId,sDate, eDate,typeIds,curLabel,rateType} = {}) {
        const {data} = await Analysis_Alert_List({
            userId: userId || getToken(),sDate, eDate,typeIds,curLabel,rateType
        });
        // console.log('Analysis_Alert',data[0])
        commit('Analysis_Alert_List', data);
    },

    async Analysis_Alert_EachStation({commit}, {urId} = {}) {
        const {data} = await Analysis_Alert_EachStation({
            userId: getToken(),urId
        });
        // console.log('Analysis_Alert',data[0])
        commit('Analysis_Alert_EachStation', data);
    },


    async Analisys({commit}, {sDate,eDate} = {}) {
        const {data} = await Analisys({
            userId: getToken(),sDate,eDate
        });
        commit('Analisys', data);
    },
    async Analisys_Sub({commit}, {sDate,eDate} = {}) {
        const {data} = await Analisys_Sub({
            userId: getToken(),sDate,eDate
        });
        commit('Analisys_Sub', data);
    },

    async DownSaleReport({commit}, {ymd} = {}) {
        const {data} = await DownSaleReport({
            userId: getToken(),ymd
        });
        commit('DownSaleReport', data);
    },


}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
