import {screenGetters} from "@/store/getters/screen";
import {userGetters} from "@/store/getters/user";
import {operateGetters} from "@/store/getters/operate";
import {companyGetters} from "@/store/getters/company";
import {analysisGetters} from "@/store/getters/analysis";
import {tagGetters} from "@/store/getters/tag";
import {privateProjectGetters} from "@/store/getters/privateProject";
import {competitiveGetters} from "@/store/getters/competitive";
import {updownGetters} from "@/store/getters/updown";

import {surveyGetters} from "@/store/getters/survey";

const getters = {
    ...screenGetters,
    ...userGetters,
    ...operateGetters,
    ...companyGetters,
    ...analysisGetters,
    ...tagGetters,
    ...privateProjectGetters,
    ...competitiveGetters,
    ...updownGetters,
    ...surveyGetters
};
export default getters
