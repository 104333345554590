import {getToken} from "@/utils/auth";

import {Label_Group_LabelIds,Label_MediaList,Label_GetMediaTag} from "@/api/tag";
import {DDSingleList} from "@/api/operate";
// import {formatSourceDataToSever} from "@/views/BCSProduct/style-xml-manager";

const state = {
    // CalculateValueList:[],
    labelList:[],
    Label_Group_LabelList:[],
    Label_MediaList:[],
    Label_GetMediaTag:[],
};

const mutations = {
    // CalculateValueList:(state, list) => {
    //     state.CalculateValueList = list;
    // },
    labelList:(state, list) => {
        state.labelList = list;
    },
    Label_Group_LabelList:(state, list) => {
        state.Label_Group_LabelList = list;
    },
    Label_MediaList:(state, list) => {
        state.Label_MediaList = list;
    },
    Label_GetMediaTag:(state, list) => {
        state.Label_GetMediaTag = list;
    },
}

let obj = {};

const actions = {
    async labelList({commit}) {
        const {data} = await DDSingleList({
            userId: getToken(),typeId:22
        });
        commit('labelList', data);
    },

    async Label_Group_LabelList({commit},{groupId}) {
        const {data} = await Label_Group_LabelIds({
            userId: getToken(),groupId
        });
        commit('Label_Group_LabelList', data);
    },

    async Label_MediaList({commit}, {mtypeIds,stationIds,key} = {}) {
        const {data} = await Label_MediaList({
            userId: getToken(),mtypeIds,stationIds,key
        });
        commit('Label_MediaList', data);
    },

    async Label_GetMediaTag({commit},{mediaId}) {
        const {data} = await Label_GetMediaTag({
            userId: getToken(),mediaId
        });
        commit('Label_GetMediaTag', data);
    },
    

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
