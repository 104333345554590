// import {createStore} from 'vuex'
// import {Analysis_Month_Week} from  "@/api/analysis";

// export default createStore({
//     state: {
//         tagsList: [],
//         collapse: false,
//         Analysis_Month:{},
//     },
//     mutations: {
//         delTagsItem(state, data) {
//             state
//                 .tagsList
//                 .splice(data.index, 1);
//         },
//         setTagsItem(state, data) {
//             state
//                 .tagsList
//                 .push(data)
//         },
//         clearTags(state) {
//             state.tagsList = []
//         },
//         closeTagsOther(state, data) {
//             state.tagsList = data;
//         },
//         closeCurrentTag(state, data) {
//             for (let i = 0, len = state.tagsList.length; i < len; i++) {
//                 const item = state.tagsList[i];
//                 if (item.path === data.$route.fullPath) {
//                     if (i < len - 1) {
//                         data
//                             .$router
//                             .push(state.tagsList[i + 1].path);
//                     } else if (i > 0) {
//                         data
//                             .$router
//                             .push(state.tagsList[i - 1].path);
//                     } else {
//                         data
//                             .$router
//                             .push("/");
//                     }
//                     state
//                         .tagsList
//                         .splice(i, 1);
//                     break;
//                 }
//             }
//         },        
//         // 侧边栏折叠
//         hadndleCollapse(state, data) {
//             state.collapse = data;
//         },

//         async setAnalysis_Month(state,{userId,sDate,eDate}){
//             const {data} = await Analysis_Month_Week({
//                 userId, sDate, eDate
//             });
//             // var data = Analysis_Month_Week({
//             //     userId, sDate, eDate
//             // });
            
//             state.Analysis_Month=data;
//             // console.log('data',state.Analysis_Month,data)
//             // commit('Analysis_Month_Week', data);
//         },
//     },
//     actions: {},
//     modules: {}
// })




// import {createApp} from 'vue'
// import App from './App.vue'
import {createStore} from 'vuex'
import getters from './getters'

// const app = createApp(App)
// Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
//   console.log('aaa',value)
  modules[moduleName] = value.default
  return modules
}, {})

const store = createStore({
    state: {
                tagsList: [],
                collapse: false,
                Analysis_Month:{},
            },
            mutations: {
                setSysTitle(state,title){
                    state.title=title;
                },
                
                delTagsItem(state, data) {
                    state
                        .tagsList
                        .splice(data.index, 1);
                },
                setTagsItem(state, data) {
                    state
                        .tagsList
                        .push(data)
                },
                clearTags(state) {
                    state.tagsList = []
                },
                closeTagsOther(state, data) {
                    state.tagsList = data;
                },
                closeCurrentTag(state, data) {
                    for (let i = 0, len = state.tagsList.length; i < len; i++) {
                        const item = state.tagsList[i];
                        if (item.path === data.$route.fullPath) {
                            if (i < len - 1) {
                                data
                                    .$router
                                    .push(state.tagsList[i + 1].path);
                            } else if (i > 0) {
                                data
                                    .$router
                                    .push(state.tagsList[i - 1].path);
                            } else {
                                data
                                    .$router
                                    .push("/");
                            }
                            state
                                .tagsList
                                .splice(i, 1);
                            break;
                        }
                    }
                },        
                // 侧边栏折叠
                hadndleCollapse(state, data) {
                    state.collapse = data;
                },
            },
  modules,
  getters
})

export default store
