import request from "@/utils/request";
// import {dealDownloadFileResponse} from "@/api/file";


// /**
//  *  1、月、周销售金额和订单量
//  * @returns {AxiosPromise}
//  * @constructor
//  */
// export function Analysis_Month_Week({userId, sDate,eDate}) {
    
//     return request({url: `/appi/Analysis/Analysis_Month_Week?uId=${userId}&sDate=${sDate}&eDate=${eDate}`})

//     // console.log('aaa')
//     // return [{"aa":1,"bb":"a"},{"aa":2,"bb":"b"}];
// }

/**
 * 数据分析-预警统计
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function Analysis_Alert({userId,sDate,eDate,typeIds,rateType}) {    
    return request({url: `/appi/Analysis/Analysis_Alert?userId=${userId}&sDate=${sDate}&eDate=${eDate}&typeIds=${typeIds}&rateType=${rateType}`})

}
/**
 * 数据分析-预警统计
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
 export function Analysis_Alert_List({userId,sDate,eDate,typeIds,curLabel,rateType}) {    
    return request({url: `/appi/Analysis/Analysis_Alert_List?userId=${userId}&sDate=${sDate}&eDate=${eDate}&typeIds=${typeIds}&curLabel=${curLabel}&rateType=${rateType}`})

}

export function Analisys({userId,sDate,eDate}) {   
    return request({url: `/appi/Analysis/Analisys?userId=${userId}&sDate=${sDate}&eDate=${eDate}`})
}

export function Analisys_Sub({userId,sDate,eDate}) {   
    return request({url: `/appi/Analysis/Analisys_Sub?userId=${userId}&sDate=${sDate}&eDate=${eDate}`})
}

export function Analysis_Alert_EachStation({userId,urId}) {   
    return request({url: `/appi/Analysis/Analysis_Alert_EachStation?userId=${userId}&urId=${urId}`})
}

/**
 * 导出销控
 * @param {*} 
 * @returns 
 */
 export function Analisys_PPT_XK({userId,ym, stationIds=''}) {
    return request({url: `/appi/Analysis/Analisys_PPT_XK?userId=${userId}&ym=${ym}&stationIds=${stationIds}`,method:'get',
        responseType: 'blob'}).then(res => {
        console.log(res);
        return dealDownloadFileResponse({res});
    })
}

export function DownSaleReport({userId,ymd}) {   
    return request({url: `/appi/Analysis/DownSaleReport?userId=${userId}&ymd=${ymd}`})
}