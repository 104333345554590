Date.prototype.myFormat = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

// Date = function (Date) {
//     MyDate.prototype = Date.prototype;
//     return MyDate;

//     function MyDate() {
//       // 当只有一个参数并且参数类型是字符串时，把字符串中的-替换为/
//       if (arguments.length === 1) {
//         let arg = arguments[0];
//         if (Object.prototype.toString.call(arg) === '[object String]' && arg.indexOf('T') === -1) {
//           arguments[0] = arg.replace(/-/g, "/");
//           // console.log(arguments[0]);
//         }
//       }
//       let bind = Function.bind;
//       let unbind = bind.bind(bind);
//       return new (unbind(Date, null).apply(null, arguments));
//     }
//   }(Date);



export function getDateArrayByBoLangXian(playDate) {
    return playDate?.split('~').map(item => getYearMonthDayBySeverTime(item)) ?? [];
}

/**
 * 'HH:mm-HH:mm' 转换成 ['HH:mm','HH:mm']
 * @param timeSeg
 * @returns {*}
 */
export function getHourAndMinuteArray({timeSeg}) {
    return timeSeg.split('-');
}

/**
 * ['HH:mm','HH:mm'] 转换成 'HH:mm-HH:mm'
 * @param timeArray
 * @returns {string}
 */
export function getHourAndMinuteString({timeArray}) {
    return timeArray.join('-');
}

export function getNextMonthDateArray() {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    console.log('date1', date)
    return getLatestMonthDateArrayByDate({date, delayNum: 1});
}

/**
 * 前后半年
 * @returns {[*, *]}
 */
export function getNextAndBeforeHalfYearDateArray() {
    const date = new Date();
    date.setMonth(date.getMonth() + 6);
    return getLatestMonthDateArrayByDate({date, delayNum: 12});
}

export function getLatestThreeMonthDateArray() {
    return getLatestMonthDateArrayByDate({date: new Date()})
}

export function getLatestHalfYearDateArray({formatValue} = {}) {
    return getLatestMonthDateArrayByDate({date: new Date(), delayNum: 6, formatValue});
}

export function getLatestOneYearDateArray() {
    return getLatestMonthDateArrayByDate({date: new Date(), delayNum: 12});
}

export function getLatestMonthDateArrayByDate({date, delayNum = 3, formatValue = 'yyyy/MM/dd'} = {}) {
    const eDate = date.myFormat(formatValue);
    date.setMonth(date.getMonth() - delayNum);
    const sDate = date.myFormat(formatValue);
    console.log('date2',sDate, eDate);
    return [sDate, eDate];
}

export function getMonthDateArrayByPrePost({date, preDelayNum = 6,postDelayNum=6, formatValue = 'yyyy/MM/dd'} = {}) {    
    // const tmp = date;
    date.setMonth(date.getMonth() - preDelayNum);    
    const sDate = date.myFormat(formatValue);
    
    // tmp.setMonth(tmp.getMonth()+ preDelayNum + postDelayNum);   
    // const eDate = tmp.myFormat(formatValue);
    const eDate = date.setMonth(date.getMonth() + preDelayNum + postDelayNum);
    console.log('bb',sDate, eDate);
    return [sDate, eDate];
}

export function getMonthDateArrayByPrePostSafari({date, preDelayNum = 6,postDelayNum=6, formatValue = 'yyyy/MM/dd'} = {}) {    
    date.setMonth(date.getMonth() - preDelayNum);
    console.log('1',date)
    const sDate = date.setMonth(date.getMonth() + preDelayNum );
    const eDate = date.setMonth(date.getMonth() + preDelayNum + postDelayNum);
    console.log(sDate, eDate);
    return [sDate, eDate];
}

export function getMonthDateArrayByPrePostSafari2({date, preDelayNum = 6,postDelayNum=6, formatValue = 'yyyy/MM/dd'} = {}) {    
    const sDate = date.setMonth(date.getMonth() + preDelayNum);
    // console.log('1',date)
    // const sDate = date.setMonth(date.getMonth() + preDelayNum );
    const eDate = date.setMonth(date.getMonth()-preDelayNum + postDelayNum);
    console.log(sDate, eDate);
    return [sDate, eDate];
}


export function getCurrentYear() {
    return new Date().getFullYear().toString();
}

export function getCurrentDate() {
    return new Date().myFormat('yyyy/MM/dd');
}

export function getYearMonthDayBySeverTime(severTime) {
    return severTime?.split('T')[0] ?? '';
}

export function getYearMonth(offset1){
    var date1 = new Date();
    date1.setMonth(date1.getMonth() + offset1);
    const date = date1.myFormat('yyyy-M');
    // 
    return date;
}

export function getCurrentTime() {
    //获取当前时间并打印
　　let yy = new Date().getFullYear();
　　let mm = new Date().getMonth()+1;
　　let dd = new Date().getDate();
　　let hh = new Date().getHours();
　　let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
　　let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
　　let time = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
    return time;

}