import {
    getScreenSet,   Analysis_Month_Week
} from '@/api/screen'

import {getToken, removeToken, setToken} from '@/utils/auth'
import {resetRouter} from '@/router'



// 用户记录
const state = {
    screenSet: [],
    Analysis_Month:{},
};

const mutations = {    
    screenSet: (state, list) => {
        state.screenSet = list;
    },

    async setAnalysis_Month(state,list){        
        state.Analysis_Month=list;
    },
}

const actions = {

    async screenSet({commit, state},{}) {
        console.log('aaa');
        return getScreenSet({userId: getToken()}).then(response => {
            // console.log('asfasdfsafdasddsasdfsadfsdf',response.data)
            commit('screenSet', response.data);
        });
        // return null;
    },

    async setAnalysis_Month({commit, state},{userId,sDate,eDate}) {
        console.log('aaa');
        return Analysis_Month_Week({
            userId, sDate, eDate
        }).then(response => {
            // console.log('asfasdfsafdasddsasdfsadfsdf',response.data)
            commit('setAnalysis_Month', response.data);
        });
        // return null;
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
