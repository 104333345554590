import {dealDownloadFileResponse} from "@/api/file";
import request from "@/utils/request";
import {getToken} from "@/utils/auth";
import { encrypt } from '@/utils/encrypt'

/**
 * 获取问卷内容
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
// export function GetProjectQuestions({userId,projectId,agent}) {    
export function GetProjectQuestions(data) {    
    console.log('data2',data)
    if (data.projectId.length <= 20) {
        data.projectId = encrypt(data.projectId)
    }
    return request({url: `/appi/Survey/GetProjectQuestions?userId=${data.userId}&projectId=${data.projectId}&agent=${data.agent}`})
}


/**
 * 获取项目列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function GetProjectList({userId,sDate,eDate,key}) {    
    return request({url: `/appi/Survey/GetProjectList?userId=${encrypt(userId)}&sDate=${sDate}&eDate=${eDate}&key=${key}`})
}

/**删除项目 */
export function DeleProject({
    projectId,userId
}) {    
    return request({
        url: `/appi/Survey/DeleProject?projectId=${encrypt(projectId)}&userId=${encrypt(userId)}`})
}

/**
 * 获取项目配额列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function GetProjectQuotaList({userId,projectId}) {    
    return request({url: `/appi/Survey/GetProjectQuotaList?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}`})
}



/**
 * 获取模板列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function GetModelList({userId,sDate,eDate,key}) {    
    return request({url: `/appi/Survey/GetModelList?userId=${encrypt(userId)}&sDate=${sDate}&eDate=${eDate}&key=${key}`})
}

/**删除模板 */
export function DeleModel({
    modelId,userId
}) {    
    return request({
        url: `/appi/Survey/DeleModel?modelId=${encrypt(modelId)}&userId=${encrypt(userId)}`})
}

/**获取模板明细 */
export function GetModelDetail({
    modelId,userId
}) {    
    return request({
        url: `/appi/Survey/GetModelDetail?modelId=${encrypt(modelId)}&userId=${encrypt(userId)}`})
}


/**获取项目问题明细 */
export function GetProjectQADetail({
    projectId,userId
}) {    
    return request({
        url: `/appi/Survey/GetProjectQADetail?projectId=${encrypt(projectId)}&userId=${encrypt(userId)}`})
}

/**设置项目状态 */
export function SetProjectStatus({
    userId,projectId,statusId
}) {    
    return request({
        url: `/appi/Survey/SetProjectStatus?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}&statusId=${statusId}`})
}

/**
 * 获取问题、答案、列项条目
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function GetQuesAnswerCol({userId,parentId,checkType}) {    
    return request({url: `/appi/Survey/GetQuesAnswerCol?userId=${encrypt(userId)}&parentId=${encrypt(parentId)}&checkType=${checkType}`})
}

/**快捷选择媒体 */
export function QuickSelectMedia({
    userId,projectId,selAIds
}) {    
    return request({
        url: `/appi/Survey/QuickSelectMedia?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}&selAIds=${selAIds}`})
}


/**
 * 获取题型列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function GetQuestionTypeList({userId}) {    
    return request({url: `/appi/Survey/GetQuestionTypeList?userId=${encrypt(userId)}`})
}
/**
 * 获取省市列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function GetProvinceCityList({userId,typeId}) {    
    return request({url: `/appi/Survey/DDList_Level2?userId=${encrypt(userId)}&typeId=${typeId}`})
}

/**
 * 获取普通DDL
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function DDList_Common({userId,typeId}) {    
    return request({url: `/appi/Survey/DDList_Common?userId=${encrypt(userId)}&typeId=${typeId}`})
}

/**
 * 项目分析
 * @param projectId
 * @param userId
 * @returns {AxiosPromise}
 */
export function ProjectAnalisys({userId,projectId,includeTest}) {    
    return request({url: `/appi/Survey/ProjectAnalisys?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}&includeTest=${includeTest}`})
}
export function ProjectAnalisysV2({userId,projectId,includeTest}) {    
    return request({url: `/appi/Survey/ProjectAnalisysV3?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}&includeTest=${includeTest}`})
}
export function ProjectAnalisysV4({userId,projectId,includeTest}) {    
    return request({url: `/appi/Survey/ProjectAnalisysV4?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}&includeTest=${includeTest}`})
}
export function ProjectAnalisysV5({userId,projectId,includeTest}) {    
    return request({url: `/appi/Survey/ProjectAnalisysV5?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}&includeTest=${includeTest}`})
}

/**
 * 获取四维动量的系数
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function GetProjectRatio({userId,projectId}) {    
    return request({url: `/appi/Survey/GetProjectRatio?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}`})
}
/**保存四维动量的系数 */
export function SaveProjectRatio(obj) {    
    obj.userId = encrypt(obj.userId)
    obj.projectId = encrypt(obj.projectId)
    console.log('a2',obj)
    return request({
        url: `/appi/Survey/SaveProjectRatio?`,
        data: obj
    })
}

/**保存渠道 */
export function SaveAgent(obj) {    
    obj.userId = encrypt(obj.userId)
    obj.projectId = encrypt(obj.projectId)
    console.log('a2',obj)
    return request({
        url: `/appi/Survey/SaveAgent?`,
        data: obj
    })
}
/**
 * 获得指定项目渠道列表
 * @param userId
 * @param key
 * @returns {AxiosPromise}
 */
export function GetProjectAgentList({userId,projectId}) {    
    return request({url: `/appi/Survey/GetProjectAgentList?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}`})
}



/**保存问卷结果 */
export function SaveAnswers({
    obj
}) {    
    console.log('a2',obj)
    return request({
        url: `/appi/Survey/SaveAnswers?`,
        data: obj
    })
}

/**保存项目 */
export function SaveProject(project) {    
    // console.log('project',project)
    project.userId = encrypt(project.userId)
    if (project.projectId !== '') {
        project.projectId = encrypt(project.projectId)
    }  
    return request({
        url: `/appi/Survey/SaveProject?`,
        data: project

    })
}

/**保存模板 */
export function SaveModel(model) {    
    // console.log('project',model.modelId,encrypt(model.modelId))
    model.userId = encrypt(model.userId)
    // if (model.modelId !== 0) {
    //     model.modelId = encrypt(model.modelId)
    // }    
    return request({
        url: `/appi/Survey/SaveModel?`,
        data: model

    })
}

/**保存项目配额 */
export function SaveProjectQuota(quotas) {    
    // console.log('project',project)
    quotas.userId = encrypt(quotas.userId)
    quotas.projectId = encrypt(quotas.projectId)
    return request({
        url: `/appi/Survey/SaveProjectQuota?`,
        data: quotas

    })
}

/**
 * 清空问卷答案
 * @param {*} flag  1:excel  2:ppt(原简单格式)   3 纯永达格式
 * @returns 
 */
export function ClearProjectAnswer({userId,projectId}) {    
    return request({url: `/appi/Survey/ClearProjectAnswer?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}`})
}


/**
 * 新版 导出用户回答的统计问卷
 * @param {*} flag  1:excel  2:ppt(原简单格式)   3 纯永达格式
 * @returns 
 */
export function UserQuesExport({userId,projectId}) {
    return request({url: `/appi/Survey/UserQuesExport?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}`,method:'get',
        responseType: 'blob'}).then(res => {
        // console.log(res);
        return dealDownloadFileResponse({res});
    })
}

export function CreateSPSSData({userId,projectId}) {
    return request({url: `/appi/Survey/CreateSPSSData?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}`,method:'get',
        responseType: 'blob'}).then(res => {
        // console.log(res);
        return dealDownloadFileResponse({res});
    })
}

/**
 * 新版 导出用户回答的明细问卷
 * @param {*} flag  1:excel  2:ppt(原简单格式)   3 纯永达格式
 * @returns 
 */
export function GetUserAnswer({userId,projectId}) {
    return request({url: `/appi/Survey/GetUserAnswer?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}`,method:'get',
        responseType: 'blob'}).then(res => {
        // console.log(res);
        return dealDownloadFileResponse({res});
    })
}



/**
 * 新版 导出统计分析PPT
 * @param {*} flag  1:excel  2:ppt(原简单格式)   3 纯永达格式
 * @returns 
 */
export function ProjectExportPPT({userId,projectId,includeTest}) {
    return request({url: `/appi/Survey/ProjectExportPPT?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}&includeTest=${includeTest}`}).then(res => {
        // console.log(res);
        return res;
    })
}


/**
 * 获得四维三力的问题选项
 * @param {*} param0 
 * @returns 
 */
export function GetSiweiSanliItem({userId,projectId}) {
    return request({url: `/appi/Survey/GetSiweiSanliItem?userId=${encrypt(userId)}&projectId=${encrypt(projectId)}`}).then(res => {
        // console.log(res);
        return res;
    })
}
