import {getToken} from "@/utils/auth";

import {Competitive_Category,Competitive_GetCityPackage} from "@/api/competitive";

const state = {
    competitiveList:[],
    cityPackageList:[],
};

const mutations = {
    competitiveList:(state, list) => {
        // console.log('aa',list)
        state.competitiveList = list;
    },

    cityPackageList:(state, list) => {
        // console.log('aa',list)
        state.cityPackageList = list;
    },
   
}

let obj = {};

const actions = {
    async competitiveList({commit},{sDate,eDate,ownerIds}) {
        const {data} = await Competitive_Category({
            userId: getToken(),sDate,eDate,ownerIds
        });
        commit('competitiveList', data);
    },

    async cityPackageList({commit},{cities}) {
        const {data} = await Competitive_GetCityPackage({
            userId: getToken(),cities
        });
        commit('cityPackageList', data);
    },
    

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
