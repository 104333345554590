
export const screenGetters = {
    
    // NumsObj: state => state.screen.screenSet[0]?state.screen.screenSet[0]:[],
    NumsObj:state=>{

        const NumsObj = state.screen.screenSet[0]?state.screen.screenSet[0]:[];
        console.log('NumsObj',NumsObj,state.screen)
        return NumsObj;
    },

    Analysis_Month : state => state.screen.Analysis_Month,

};