import {getToken} from "@/utils/auth";

import {
    ImportDataToTmp
} from "@/api/updown";


const state = {
    ImportDataToTmp:[],    
};

const mutations = {  
    ImportDataToTmp: (state, list) => {
        state.ImportDataToTmp = list;
    },

}

let obj = {};

const actions = {
    async ImportDataToTmp({commit}, {userId,typeId, formData} = {}) {
        const {data} = await ImportDataToTmp({
            userId: userId || getToken(),typeId, formData
        });
        commit('ImportDataToTmp', data);
    },


}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
