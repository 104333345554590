import {
    getUserDepartmentList,
    getUserCompany,
    getUserList,
    getUserMenu,
    getUserRoleList,
    login,
    logout,
    resetPassword,
    setUserModify,
    AdminCompanyModify,
    UserExport
} from '@/api/user'
import {getToken, removeToken, setToken,setRoleToken,setSecurityToken} from '@/utils/auth'
import {getRoleModuleList,userRoleDelete,userRoleList,PermissionOptions,UserRoleDetail_AllModule,userRoleModify,getCompanyRoleList,} from '@/api/role';
import {resetRouter} from '@/router'
import {generateDepartmentList, generateSlideData} from "@/utils/data-generator";



// 用户记录
const state = {
    // token: getToken(),
    departmentName: '',//部门名称
    departmentId: 0,
    token: 0,
    userId: 0,//用户id
    name: '',//用户姓名
    telNo: '',//电话
    mail: '',//邮箱
    roleId: -1,//角色ID,
    roleName: '',//角色名称,
    sDate: '',//有效开始时间
    eDate: '',//有效截止时间
    isAdmin: false,//是否部门管理员
    isAdminCompany:false,//是否管理单位
    rowTime: '',//添加时间
    slideData: [],//侧边栏数据
    sidebarDefaultIndex: 'Home/Index',//侧边栏数据
    companyId: -1,//所在公司Id
    userList: [],//用户列表数据
    departmentList: [],//部门列表
    roleList: [],//角色列表
    departmentTargetList: [],//部门指标列表
    ul:[],

    roleModuleList: [],
    userRoleList: [],
    companyRoleList:[],
};

const mutations = {
    SET_USER: (state, user) => {
        console.log('do set user')
        state.userId = user.userId;
        state.departmentName = user.departmentName;
        state.departmentId = user.departmentId;
        state.name = user.name;
        state.telNo = user.telNo;
        state.mail = user.mail;
        state.roleId = user.roleId;
        state.roleName = user.roleName;
        state.sDate = user.sDate;
        state.eDate = user.eDate;
        state.isAdmin = user.isAdmin;
        state.isAdminCompany=user.isAdminCompany;
        state.rowTime = user.rowTime;
        state.token = user.userId;
    },
    SET_TOKEN: (state, token) => {
        console.log('do set token')
        state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_SLIDE_DATA: (state, slideData) => {
        // console.log('slideData',slideData)
        state.slideData = slideData
    },
    SET_COMPANY_ID: (state, companyInfo) => {
        // console.log('do set companyid',companyInfo)
        state.companyId = companyInfo;
    },
    
    SET_DEPARTMENT_LIST: (state, list) => {
        state.departmentList = list;
    },
    // SET_ROLE_LIST: (state, list) => {
    //     state.roleList = list;
    // },
    SET_USER_LIST: (state, list) => {
        state.userList = list;
        state.ul = list;
    },

    SET_ROLE_LIST_ITEM: (state, roleItem) => {
        const {roleId} = roleItem;
        if (roleId) {
            for (let item of state.roleList) {
                if (roleId === item.roleId) {
                    item = roleItem;
                    break;
                }
            }
        }
    },

    SET_DEPARTMENT_TARGET_LIST: (state, list) => {
        state.departmentTargetList = list;
    },

    SET_USER_LIST_ITEM: (state, user) => {
        console.log('user',user)
        const {userId} = user;
        let itemIndex = state.userList.findIndex(item => userId === item.userId);
        if (itemIndex !== -1) {
            state.userList[itemIndex] = user;
        } else {
            state.userList.unshift(user);
        }
    },

    SET_ROLE_MODULE_LIST: (state, list) => {
        state.roleModuleList = list;
    },
    SET_ROLE_LIST: (state, list) => {
        // console.log('SET_ROLE_LIST',list)
        state.userRoleList = list;
    },
    SET_COMPANY_ROLE_LIST: (state, list) => {
        console.log('set cr list',list)
        state.companyRoleList = list;
    },
}

const actions = {

    async getRoleModuleList({commit}) {
        const {data} = await getRoleModuleList({userId: getToken()});
        commit('SET_ROLE_MODULE_LIST', data.map(item => ({
            value: item.moduleId,
            label: item.moduleName,
            permissionsCheckArray: [...PermissionOptions],
            isIndeterminate: false,
            checkAll:true,
        })));
    },

    async userRoleDelete({commit}, {roleId}) {
        return userRoleDelete({userId: getToken(), roleId});
    },
    // async userRoleList({commit, rootState}, {key = ''} = {}) {
    //     commit('SET_ROLE_LIST', (await userRoleList({userId: getToken(), key})).data);
    // },
    async UserRoleDetail_AllModule({commit},roleId) {
        const {data} = await UserRoleDetail_AllModule({roleId});
        // console.warn('UserRoleDetail_AllModule',data);
       const list =  data.map(item => {
            const {canAdd, canDelete, canExport, canModify, canQuery,canUpdown,selected} = item;
            // console.warn('itemitemitemitemitemitem',item);
            const permissionsCheckArray = [];
            if (canAdd) {
                permissionsCheckArray.push('添加');
            }
            if (canDelete) {
                permissionsCheckArray.push('删除');
            }
            if (canExport) {
                permissionsCheckArray.push('导出');
            }
            if (canModify) {
                permissionsCheckArray.push('修改');
            }
            if (canQuery) {
                permissionsCheckArray.push('系数设置');
            }
			if (canUpdown) {
                permissionsCheckArray.push('上下线');
            }
            const checkAll = permissionsCheckArray.length === PermissionOptions.length;
            // console.warn('permissionsCheckArraypermissionsCheckArraypermissionsCheckArraypermissionsCheckArray',permissionsCheckArray);
            return  {
                selected: !!selected,
                value: item.moduleId,
                label: item.moduleName,
                permissionsCheckArray,
                isIndeterminate: false,
                checkAll,
            };

        })
        
        commit('SET_ROLE_MODULE_LIST', list);
    },
    async userRoleModify({commit, state, rootState}, {roleId, roleName, remark, rightArray}) {
        // console.log('user:',rootState.user);
        return userRoleModify({
            userId: getToken(),
            companyId: rootState.user.companyId,
            roleId,
            roleName,
            remark,
            rightArray
        });
    },

    async companyRoleList({commit, rootState}, {key = ''} = {}) {
        commit('SET_COMPANY_ROLE_LIST', (await getCompanyRoleList({userId: getToken()})).data);
    },

    
    // user login
    login({commit}, userInfo) {
        const {username, password,ip,cityName} = userInfo
        
        return new Promise((resolve, reject) => {
            login({uName: username.trim(), uPswd: password,ip,cityName}).then(response => {
                const {data: [user], code,token} = response
                console.log('login response',response);
                if (code === 1) {
                    console.log('data=', user);
                    commit('SET_USER', user);
                    setToken(user.userId)
                    setRoleToken(user.roleId)
                    setSecurityToken(token);
                    // console.log('gettoken',getToken())
                    resolve()
                } else {
                    reject(new Error('账号或密码错误'));
                }
            }).catch(error => {
                reject(error)
            })
        })
    },
    async getUserMenu({commit, state}) {
        return getUserMenu({userId: getToken()}).then(response => {
            const {data} = response;
            commit('SET_SLIDE_DATA', generateSlideData({data}));
        });
    },

    async getUserCompany({commit, state}) {
        return getUserCompany({userId: getToken()}).then(response => {
            commit('SET_COMPANY_ID', response.data[0].companyId);
        });
    },

    async getUserRoleList({commit, state}, {key = ''}) {
        // console.log('key')
        return getUserRoleList({userId: getToken(), key}).then(response => {
            // console.log('aaabbb',response.data)
            commit('SET_ROLE_LIST', response.data);
        })
    },


    async getUserList({commit, state,dispatch}, key = '') {
        return getUserList({userId: getToken(),key}).then(response => {
            commit('SET_USER_LIST', response.data);
        });
    },

    async setUserModify({commit, state}, {userId, userName, name, telNo, mail, companyId, roleId, sDate = '', eDate = '', isAdmin, optUserId}) {
        // console.log('userId',userId)
        return setUserModify({
            uId: userId,
            userName,
            name,
            telNo,
            mail,
            departmentId:companyId,
            roleId,
            sDate: '',//sDate.split('T').shift(),
            eDate: '',//eDate.split('T').shift(),
            isAdmin,
            optUserId: state.token
        }).then(response => {
            commit('SET_USER_LIST_ITEM', response.data[0]);
        });

    },

    async AdminCompanyModify({commit, state}, {companyId, companyName,companyCode,logo='',typeId, gradeId,account='',bank='',regAmount='',regDate='',address='',contactUser='',tel=''}) {
        return AdminCompanyModify({
            companyId,
            companyName,
            companyCode,
            logo,
            typeId, 
            gradeId,
            account,
            bank,
            regAmount,
            regDate,
            address,
            contactUser,
            tel,
            uId: state.token
        }).then(
            // response => {
            //     commit('SET_USER_LIST_ITEM', response.data[0]);
            // }
        );

    },
  
    // user logout
    logout({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            logout(state.token).then(() => {
                commit('SET_TOKEN', '')
                commit('SET_ROLES', [])
                removeToken()
                resetRouter()

                // reset visited views and cached views
                // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
                dispatch('tagsView/delAllViews', null, {root: true})

                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    resetPassword({commit}, userInfo) {
        const {username, password} = userInfo;
        return new Promise((resolve, reject) => {
            resetPassword({uName: username, newPswd: password}).then(response => {
                const {code} = response;
                if (code === 1) {
                    resolve();
                } else {
                    reject()
                }
            }).catch(reject);
        })

    },
    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },

    async UserExport({commit}, {sDate,eDate}) {
        return UserExport({
            sDate,eDate,
            userId: getToken(),
        });
    },

    async getUserDepartmentList({commit, state}, key = '') {
        return getUserDepartmentList({userId: getToken(), companyId: state.companyId, key}).then(response => {
            commit('SET_DEPARTMENT_LIST', generateDepartmentList({data: response.data}));
        });
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
