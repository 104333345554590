import {dealDownloadFileResponse} from "@/api/file";
import request from "@/utils/request";
import {getToken} from "@/utils/auth";

/**修改基础标签 */
export function Label_Save({
    userId,id,pId,name
}) {
    return request({
        url: `/appi/Tag/Label_Save?userId=${userId}&id=${id}&pId=${pId}&name=${name}`
        // ,data: {
        //     userId,projectId,projectName,cateId, brand,budget,cpm,frequency,personCnt,reachRate,ledDiscount,dxDiscount,condi
        // }
    })
}

export function Label_Delete({userId,id}) {   
    // console.log("dothis") 
    return request({url: `/appi/Tag/Label_Delete?userId=${userId}&id=${id}`})
}

export function Label_Group_LabelIds({userId,groupId}) {   
    // console.log("dothis") 
    return request({url: `/appi/Tag/Label_Group_LabelIds?userId=${userId}&groupId=${groupId}`})
}

export function Label_Group_Save({id,name,pId, memo,typeId,labelIds}) {   
    // console.log("dothis") 
    return request({url: `/appi/Tag/Label_Group_Save?`,
        data: {
            userId: getToken(),id,name,pId, memo,typeId,labelIds
        }
    })
}

export function Label_Group_Delete({userId,id}) {   
    // console.log("dothis") 
    return request({url: `/appi/Tag/Label_Group_Delete?userId=${userId}&id=${id}`})
}

export function Label_MediaList({userId,mtypeIds,stationIds,key}) {   
    // console.log("dothis") 
    return request({url: `/appi/Tag/Label_MediaList?userId=${userId}&mtypeIds=${mtypeIds}&stationIds=${stationIds}&key=${key}`})
}

export function Label_SetMediaTag({mediaIds,tagIds,append=0}) {   
    // console.log("dothis") 
    return request({url: `/appi/Tag/Label_SetMediaTag?`,
        data: {
            userId: getToken(),mediaIds,tagIds,append
        }
    })
}

export function Label_GetMediaTag({userId,mediaId}) {   
    // console.log("dothis") 
    return request({url: `/appi/Tag/Label_GetMediaTag?userId=${userId}&mediaId=${mediaId}`})
}

