import {getToken} from "@/utils/auth";

import {SavePrivateProject,DelPrivateProject,GetPrivateProjectList} from "@/api/privateProject";

const state = {
    privateProjectList:[],

};

const mutations = {
    privateProjectList:(state, list) => {
        // console.log('aa',list)
        state.privateProjectList = list;
    },
   
}

let obj = {};

const actions = {
    async privateProjectList({commit},{key,sDate,eDate,isPublic,fileTypeId}) {
        const {data} = await GetPrivateProjectList({
            userId: getToken(),key,sDate,eDate,isPublic,fileTypeId
        });
        commit('privateProjectList', data);
    },

    

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
