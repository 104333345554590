<template>
    <div class="header">
        <!-- 折叠按钮 -->
        <div class="logo" style="display:flex;">
            <el-image style="height: 1.8rem;    margin-top: 1.3rem;    margin-left: 1.3rem;" src="https://hfweb.fusewise-info.com/SurveyService/UploadBCSFiles/logo1.png" fit="contain"></el-image>
            <!-- <div style="margin-left:-150px;margin-top: 10px;" class="collapse-btn" @click="collapseChage">
                <i v-if="!collapse" class="el-icon-s-fold"></i>
                <i v-else class="el-icon-s-unfold"></i>
            </div> -->
            <div style="display: flex;justify-content: center;margin-left:-25px;">
                <div style="font-weight: 600;margin-left:5.7rem;color: #f0f6fc;font-size: 18px;">TEM接触点效益智能管理系统</div>
            </div>

            <!-- 融研通 -->
        </div>
        <div >
            <!-- <div class="newsList" v-if="unreadMessageList.length>0" > 
                <el-carousel height="60px" style="margin-top:8px;margin-left:30px;" direction="horizontal"  interval="5000" indicator-position="none" :autoplay="ture">
                    <el-carousel-item v-for="item in unreadMessageList" :key="item.msgItemId">
                        <router-link to="/tabs">
                            <span class="el-icon-warning-outline" style="margin-left:20px;color:coral;"></span>
                            <a href="#" style="font-size: large;color: coral;margin-left:5px;">{{item.shortRemark}}</a> 
                        </router-link>
                    </el-carousel-item>
                </el-carousel>
            </div> -->
        </div>

        <div :class="'header-right-none'">
            <div class="header-user-con">      
                <!-- 用户头像 -->
                <div class="user-avator">
                    <img src="../assets/img/img.jpg" />
                </div>
                <!-- 用户名下拉菜单 -->
                <!-- <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item divided command="chgPswd">修改密码</el-dropdown-item>
                            <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown> -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <template #dropdown>
                        <!-- <el-dropdown-item v-for="(item,index) in questionTypeList" :key="index" @click="onQuesEdit(item.dictId,-1,indexq)" divided>{{item.name}}</el-dropdown-item> -->
                        <el-dropdown-item divided command="chgPswd">修改密码</el-dropdown-item>
                        <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                    </template>
                </el-dropdown>   
            </div>
        </div>

    </div>
</template>
<script>
import {getCurrentDate} from "@/utils/time";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            fullscreen: false,
            name: "linxin",
            message: 0
        };
    },
    computed: {
        username() {
            // let username = localStorage.getItem("ms_username");
            let username = localStorage.getItem("ms_name");
            return username ? username : this.name;
        },
        cartList(){
            // var cl = this.$store.state.cart.cartList;
            // console.log('cl',cl)
            return this.$store.state.cart.cartList;
        },
        collapse() {
            // console.log('this.$store.state',this.$store.state)
            return this.$store.state.collapse;
        },
        // ...mapGetters(['unreadMessageList','readedMessageList'])
    },
    async created() {
        // console.log('aa')
		await this.onSearch();
	},

    methods: {
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command == "loginout") {
                localStorage.removeItem("ms_username");
                this.$router.push("/login");
            }
            if (command == "chgPswd"){
                this.$router.push("/reset");
            }
        },

  
        // 侧边栏折叠
        collapseChage() {
            this.$store.commit("hadndleCollapse", !this.collapse);
        },

        onSearch() {
				// const {dataArray: [sDate, eDate], status, msgType} = this.searchForm;
                const eDate = getCurrentDate();
				// this.$store.dispatch('operate/unreadMessageList', {sDate:'2022-3-1', eDate, force: true}).then(()=>{
                //     this.message = this.unreadMessageList.length;
                // });

			},
    },
    mounted() {
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
    }
};
</script>
<style scoped>
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    font-size: 22px;
    color: #fff;
}
.collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 70px;
}

.item {
  margin-top: -10px;
  margin-right: 10px;
}
.header .logo {
    float: left;
    width: 550px;
    line-height: 70px;
}
.header-right {
    float: right;
    padding-right: 50px;
    margin-top: -85px;
}
.header-right-none {
    float: right;
    padding-right: 50px;
   
}

.header-user-con {
    display: flex;
    height: 70px;
    align-items: center;
}

.newsList{
    width: 1000px;
    padding-top: 16px;
}
.btn-fullscreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell {
    color: #fff;
}
.user-name {
    margin-left: 10px;
}
.user-avator {
    margin-left: 20px;
}
.user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.el-dropdown-link {
    color: #fff;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
</style>
