import {dealDownloadFileResponse} from "@/api/file";
import request from "@/utils/request";
import {getToken} from "@/utils/auth";


export function Competitive_Category({userId,sDate,eDate,ownerIds}) {   
    // console.log("dothis") 
    return request({url: `/appi/Competitive/Competitive_Category?userId=${userId}&sDate=${sDate}&eDate=${eDate}&ownerIds=${ownerIds}`})
}

export function Competitive_GetCityPackage({userId,cities}) {   
    // console.log("dothis") 
    return request({url: `/appi/Competitive/Competitive_GetCityPackage?userId=${userId}&cities=${cities}`})
}

