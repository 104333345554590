import {setLeve1Check,setLeve3Check} from "@/utils/data-generator";

export const operateGetters = {
    mediaTypeList:state => state.operate.mediaTypeList,
    stationList:state => state.operate.stationList,
    positionList:state => state.operate.positionList,
    packageList:state => state.operate.packageList,
    ageList:state => state.operate.ageList,
    genderList:state => state.operate.genderList,
    targetList:state => state.operate.targetList,
    outComeList:state => state.operate.outComeList,
    areaStationList:state => state.operate.areaStationList,
    juDuanSuoList:state => state.operate.juDuanSuoList,
    lujuXianluList:state => state.operate.lujuXianluList,
    lieCheInnerJiaGeLeiXingList:state => state.operate.lieCheInnerJiaGeLeiXingList,
    lieCheTvJiaGeLeiXingList:state => state.operate.lieCheTvJiaGeLeiXingList,
    InnerTrainPriceList:state => state.operate.InnerTrainPriceList,
    TvTrainPriceList:state => state.operate.TvTrainPriceList,

    categoryList:state => state.operate.categoryList,
    dxTypeList:state => state.operate.dxTypeList,
    productList:state => state.operate.productList,
    CalculateValueList:state => state.operate.CalculateValueList,
    CalculateValueListNew:state => state.operate.CalculateValueListNew,
    CalculateValue_ProvinceList:state => state.operate.CalculateValue_ProvinceList,
    CalculateProjectValueList:state => state.operate.CalculateProjectValueList,
    projectList:state => state.operate.projectList,
    projectMediaList:state => state.operate.projectMediaList,
    projectMediaList_NotDetail:state => state.operate.projectMediaList_NotDetail,
    GetProjectProductMediaList:state => state.operate.GetProjectProductMediaList,
    stationDetail:state => state.operate.stationDetail,
    indexData:state => state.operate.indexData,
    // CalculateMulti:state => state.operate.CalculateMulti,
    CalculateMulti:state => {
        return  state.operate.CalculateMulti[0] ?? [];
    }, 
    // GetGroupandSimpleMedia:state => state.operate.GetGroupandSimpleMedia,
    // GetMediaDetail:state => {
    //     return  state.operate.GetMediaDetail[0] ?? [];
    // }, 
    // GetMediaDetailRemark:state => {
    //     return  state.operate.GetMediaDetail[1] ?? [];
    // }, 
    GetPlanPrice:state => state.operate.GetPlanPrice,
    GetPlanPrice_ByCost:state => state.operate.GetPlanPrice_ByCost,
    GetPlanPrice_ByGroupIds:state => state.operate.GetPlanPrice_ByGroupIds,
    CalculateMultiSmart:state => {
        return  state.operate.CalculateMultiSmart[0] ?? [];
    }, 
    CalculateMultiSmart_WithoutBudget:state => {
        return  state.operate.CalculateMultiSmart_WithoutBudget[0] ?? [];
    }, 
    GetGroupList:state => state.operate.GetGroupList,
    GetProductList:state => state.operate.GetProductList,
    GetValidProductList:state => state.operate.GetValidProductList,
    GetValidStandardProductList:state => state.operate.GetValidStandardProductList,
    GetCartData:state => state.operate.GetCartData,
    GetMediaTagList:state => state.operate.GetMediaTagList,
    GetTagGroupList:state => state.operate.GetTagGroupList,
    GetGroupList_ByTag:state => state.operate.GetGroupList_ByTag,
    GetStationPositionMediaList:state => state.operate.GetStationPositionMediaList,
    
    GetProductMediaList:state => state.operate.GetProductMediaList,
    GetGroupDetail:state => state.operate.GetGroupDetail,
    GetPackageMediaList:state => state.operate.GetPackageMediaList,
    Media_LedList:state => state.operate.Media_LedList,
    Media_DxList:state => state.operate.Media_DxList,
    Media_DigitalList:state => state.operate.Media_DigitalList,
    Media_TrainList:state => state.operate.Media_TrainList,
    // Media_AllList:state => state.operate.Media_AllList,

    GetProjectSmartList:state => state.operate.GetProjectSmartList,
    GetAdVersionList:state => state.operate.GetAdVersionList,

    // 

    unreadMessageList: state => state.operate.unreadMessageList,
    readedMessageList: state => state.operate.readedMessageList,

    // Analisys_Alert: state => state.operate.Analisys_Alert,

    BuildCartItem: state => state.operate.BuildCartItem,
    GetStationInfo: state => state.operate.GetStationInfo,

    BuildCartItem_Multi: state => state.operate.BuildCartItem_Multi,
    AreaList: state => {
        // console.log('data10',state.operate.AreaList)
        return setLeve1Check({data:state.operate.AreaList});
    },
    ReportTypeList: state => {
        // console.log('data10',state.operate.AreaList)
        return setLeve1Check({data:state.operate.ReportTypeList});
    },
    MediaOwnerList: state => {
        // console.log('data10',state.operate.AreaList)
        return setLeve1Check({data:state.operate.MediaOwnerList});
    },
    ProvinceList: state => {
        return setLeve1Check({data:state.operate.ProvinceList});
    },
    ProvinceCityStationList: state => {
        return setLeve3Check({data:state.operate.ProvinceCityStationList});
    },
    CityLevelList: state => state.operate.CityLevelList,
    intelligenceAreaList: state => state.operate.intelligenceAreaList,
    SmartSuggest: state => state.operate.SmartSuggest,

    goldenList: state => state.operate.goldenList,
    GetTeSeTaoCanList: state => state.operate.GetTeSeTaoCanList,

    GetMgrStationList: state => state.operate.GetMgrStationList,
    AreaProvinceCityList: state => state.operate.AreaProvinceCityList,
    stationGradeList: state => state.operate.stationGradeList,
};