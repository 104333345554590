import {dealDownloadFileResponse} from "@/api/file";
import request from "@/utils/request";

export function ProjectExport({userId,typeId}) {
    return request({url: `/appi/UpDown/DownloadModel?`
                    ,data: {
                        userId,typeId
                    }
                    ,method:'post'
                    ,responseType: 'blob'})
            .then(res => {
        console.log(res);
        return dealDownloadFileResponse({res});
    })
   
}


/**
 * 资源&产品包导入到临时表
 * @param userId
 * @param formData
 * @returns {AxiosPromise}
 * @constructor
 */
 export function ImportDataToTmp({userId,typeId, formData}) {
    return request({url: `/appi/UpDown/ImportDataToTmp?userId=${userId}&typeId=${typeId}`, data: formData})
}


/**
 * 资源&产品包导入到正式表
 * @param userId
 * @param formData
 * @returns {AxiosPromise}
 * @constructor
 */
 export function ImportDataToDB({userId,fileType, logId}) {
    return request({url: `/appi/UpDown/ImportDataToDB?userId=${userId}&fileType=${fileType}&logId=${logId}`})
}


///下载Excel模板
///1:LED；99：产品包
export function DownloadTemplateFile({userId,typeId}){
    return request({
        url: `/appi/UpDown/DownloadModel?userId=${userId}&typeId=${typeId}`,
        method: 'get',
        responseType: 'blob'
    }).then(res => {
        console.log(res);
        return dealDownloadFileResponse({res});
    })

}
